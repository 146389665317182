<div class="container w-90">
    <div class="row">
        <div class="col-12 d-md-none">
            <a [routerLink]="'/hours-teams'" class="text-decoration-none">
                <span class="time-icon-back fs-16"></span>
            </a>
        </div>
        <div class="col-12 d-none d-md-block">
            <app-breadcrumb [crumbs]="breadcrumb"> </app-breadcrumb>
        </div>

    <div class="col-12 d-flex justify-content-between mt-3 mt-md-4">
        <p class="title mb-0">Consulta de registro de horas.</p>
        <div class="d-md-flex d-none align-items-center">
            <button class="btn btn-secondary mr-2" (click)="openIncidenceDialog()">Agregar Incidencia</button>
            <button class="btn btn-primary" (click)="downloadPDF()">Descargar reporte</button>
        </div>
    </div>

    <div class="col-12 d-block mt-4">
        <p class="title mb-0">{{ employeeName }}</p>
        <p class="subtitle mb-0 mt-3">Puedes consultar las actividades por mes.</p>
    </div>

    <div class="col-12 mt-4 mt-md-5">
        <div class="row">
            <div class="col-12 col-md-auto">
                <mat-card class="w-100 d-none d-md-flex">
                    <mat-calendar [(selected)]="selected" (selectedChange)="onDateSelected($event)" [minDate]="START_DATE" [maxDate]="TODAY"></mat-calendar>
                </mat-card>

                <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 d-md-none deep-mat-field">
                    <mat-label>Selecciona una fecha</mat-label>
                    <input matInput readonly [matDatepicker]="picker" [value]="selected" [min]="START_DATE" [max]="TODAY" (click)="picker.open()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker">
                        <span class="time-icon-calendar_default fs-24" matDatepickerToggleIcon></span>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker startView="multi-year" (monthSelected)="setMonthAndYear($event, picker)"></mat-datepicker>
                  </mat-form-field>
            </div>
            <div class="col-12 col-md mt-4 mt-md-0">
                <div class="chart-box">
                <div class="title-chart">
                    <span class="mr-2">Actividades cargadas en:</span>
                    <span class="font-weight-bold"> {{ selected | date:'MMMM yyyy' | titlecase }}</span>
                </div>
                <div class="row padding">
                    <div class="col-md-5 col-4">
                        <div class="bar-chart">
                            <div class="d-flex align-items-center">
                                <span class="font-24 time-icon-status_approved mr-2"><span class="path1"></span><span
                                        class="path2"></span></span>
                                <span class="bar-text d-md-flex d-none mr-2">Aprobadas:</span>
                                <span class="bar-text"><b>{{chartData.approved.hours | number : '1.2-2'}} h</b></span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="font-24 time-icon-status_pending mr-2"><span class="path1"></span><span
                                        class="path2"></span></span>
                                <span class="bar-text d-md-flex d-none mr-2">Por aprobar:</span>
                                <span class="bar-text"><b>{{chartData.pending.hours | number : '1.2-2'}} h</b></span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="font-24 time-icon-status_rejected mr-2"><span class="path1"></span><span
                                        class="path2"></span></span>
                                <span class="bar-text d-md-flex d-none mr-2">Rechazadas:</span>
                                <span class="bar-text"><b>{{chartData.rejected.hours | number : '1.2-2'}} h</b></span>
                            </div>
                            <div class="d-flex align-items-center">
                                <img src="assets/icons/status/status_empty.svg" alt="status empty" class="mr-2" width="24px" height="24px">
                                <span class="bar-text d-md-flex d-none mr-2">Pendientes:</span>
                                <span class="bar-text"><b>{{chartData.empty.hours | number : '1.2-2'}} h</b></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 col-8">
                        <div class="bar-chart">
                            <div  class="bar-container" style="--color: 140, 206, 23;">
                                <div class="bar" [style.width]="chartData.approved.percentage + '%'"
                                    [attr.data-value]="chartData.approved.percentage"></div>
                            </div>
                            <div  class="bar-container" style="--color: 255, 139, 44;">
                                <div class="bar" [style.width]="chartData.pending.percentage + '%'"
                                    [attr.data-value]="chartData.pending.percentage"></div>
                            </div>
                            <div  class="bar-container" style="--color: 228, 37, 75;">
                                <div class="bar" [style.width]="chartData.rejected.percentage + '%'"
                                    [attr.data-value]="chartData.rejected.percentage"></div>
                            </div>
                            <div  class="bar-container" style="--color: 100, 150, 228;">
                                <div class="bar" [style.width]="chartData.empty.percentage + '%'"
                                    [attr.data-value]="chartData.empty.percentage"></div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>

    </div>

    <div class="row divider-hours">
        <div class="col-12 d-md-flex align-items-center justify-content-between">
            <p class="title mb-4 mb-md-0">Horas registradas en: <b>{{selected | date:'MMMM yyyy' | titlecase}}</b></p>
            <div class="d-flex justify-content-between">
                <div class="d-md-flex text-center align-items-center mr-3">
                    <span class="time-icon-status_approved mr-1 fs-24"><span class="path1"></span><span
                            class="path2"></span></span>
                    <p class="subtitle mb-0">Aprobada</p>
                </div>
                <div class="d-md-flex text-center align-items-center mr-3">
                    <span class="time-icon-status_pending mr-1 fs-24"><span class="path1"></span><span
                            class="path2"></span></span>
                    <p class="subtitle mb-0">Por aprobar</p>
                </div>
                <div class="d-md-flex text-center align-items-center">
                    <span class="time-icon-status_rejected mr-1 fs-24"><span class="path1"></span><span
                            class="path2"></span></span>
                    <p class="subtitle mb-0">Rechazada</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3 mt-md-5">
        <table class="table-border">
            <thead>
                <tr class="row-title">
                    <th scope="col" style="width: 10%;">Fecha</th>
                    <th scope="col" style="width: 15%;">Actividad</th>
                    <th scope="col" style="width: 15%;">Tipo de actividad</th>
                    <th scope="col" style="width: 10%;">Tiempo</th>
                    <th scope="col" style="width: 40%; max-width: 150px;">Descripción</th>
                    <th scope="col" style="width: 10%;">Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let activity of paginatedActivities">
                    <td>{{ activity.date | date: 'dd/MM/yyyy' | titlecase }}</td>
                    <td>{{ activity.time }}</td>
                    <td>{{ activity.oportunity }}</td>
                    <td>{{ activity.hours }} h {{activity.minutes}} min</td>
                    <td style="max-width: 150px;" class="text-truncate">{{ activity.activities }}</td>
                    <td class="d-flex justify-content-center">
                        <img [src]="'../../../../assets/icons/status/status_' + activity.status + '.svg'" width="24px"
                            alt="">
                        <div class="dropleft ml-3">
                            <span class="btn dropdown-toggle" type="button" data-toggle="dropdown"
                                aria-expanded="false">
                                <img src="../../../../assets/icons/options.svg" alt="">
                            </span>
                            <div class="dropdown-menu">
                                <div class="dropdown-item d-flex align-items-center cr-pointer"
                                    *ngIf="activity.status == 'pending'"
                                    (click)="approveActivity(activity._id)">
                                    <span class="time-icon-status_approved mr-2"><span class="path1"></span><span
                                            class="path2"></span></span>
                                    <span>Aprobar</span>
                                </div>
                                <div class="dropdown-item d-flex align-items-center cr-pointer"
                                    *ngIf="activity.status == 'pending'"
                                    (click)="openRejectDialog(activity._id)">
                                    <span class="time-icon-status_rejected mr-2"><span class="path1"></span><span
                                            class="path2"></span></span>
                                    <span>Rechazar</span>
                                </div>
                                <div class="dropdown-item d-flex align-items-center cr-pointer"
                                    [routerLink]="['/collaborator/details', activity._id]">
                                    <span class="time-icon-menu_time mr-2"></span>
                                    <span>Detalles de actividad</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div id="accordion" class="d-md-none w-100">
            <div *ngFor="let activity of paginatedActivities; index as i" class="card">
                <div class="card-header" id="heading{{i}}">
                    <h5 class="mb-0">
                        <button class="btn d-flex justify-content-between" (click)="toggleCollapse(i)">
                            <div class="d-flex align-items-center">
                                <img [src]="'../../../../assets/icons/status/status_' + activity.status + '.svg'"
                                    width="20px" alt="">
                                <span class="tag-text ml-2">{{ activity.date | date: 'dd/MM/yyyy' | titlecase }}</span>
                            </div>
                            <div class="tag-text d-flex align-items-center">
                                <span>{{ activity.hours }} h {{activity.minutes}} min</span>
                                <span class="time-icon-arrow_collapse ml-2"></span>
                            </div>
                        </button>
                    </h5>
                </div>

                <div [id]="'collapse' + i" class="collapse" [ngClass]="{'show': activeIndex === i}"
                    [attr.aria-labelledby]="'heading' + i">
                    <div class="card-body">
                        <p class="row-title">Actividad:</p>
                        <p class="tag-text">{{ activity.time }}</p>

                        <p class="row-title">Tipo de actividad:</p>
                        <p class="tag-text">{{ activity.oportunity }}</p>

                        <p class="row-title">Descripción:</p>
                        <p class="tag-text">{{ activity.activities }}</p>

                        <div class="d-flex justify-content-center mb-4">
                            <button class="btn button-decline mr-3" (click)="openRejectDialog()" *ngIf="activity.status == 'pending'">
                                <span class="time-icon-button_approve mr-2"></span>
                                Rechazar
                            </button>
                            <button class="btn button-accept" (click)="approveActivity(activity.activityId)" *ngIf="activity.status == 'pending'">
                                <span class="time-icon-button_decline mr-2"></span>
                                Aprobar
                            </button>
                        </div>

                        <a class="tag-text"
                            [routerLink]="['/collaborator/details', activity._id]">Detalles
                            de la actividad</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="totalPages >= 1" class="row justify-content-center mt-4 mb-5">
        <div class="row justify-content-center">
            <div class="pagination">
                <span class="time-icon-arrow_collapse" style="rotate: 180deg;" (click)="prevPage()"></span>

                <ng-container *ngFor="let page of pages">
                    <span [class.active]="currentPage === page" (click)="changePage(page)" class="page-number">
                        {{ page }}
                    </span>
                </ng-container>

                <span class="time-icon-arrow_collapse" (click)="nextPage()"></span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="d-block d-md-none align-items-center">
                <button class="btn btn-secondary w-100 mb-3" (click)="openIncidenceDialog()">Agregar Incidencia</button>
                <button class="btn btn-primary w-100 mb-3" (click)="downloadPDF()">Descargar reporte</button>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertComponent, AlertDialogData } from '@components/alert/alert.component';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { Swal2Loader } from '@consts/swal2-loader';
import { EmployeesService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TeamService } from 'src/app/services/team/team.service';
import Swal from 'sweetalert2';

interface Area {
  id: string;
  area: string;
  backup?: string
}
interface Backup {
  id: string;
  area: string;
  employee: string;
  avatar: string;
}
@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss']
})
export class BackupComponent {
  public area: Area[] = []
  public employee: Backup[] = [];
  public backupForm!: FormGroup;
  public breadcrumb: Breadcrumb[] = [
    CRUMBS.areaLeadMain,
    CRUMBS.areaLeadBackup,
  ];
  public today = new Date();
  userInfoLogged;

  constructor(private formBuilder: FormBuilder, private dialog: MatDialog, private router: Router, private auth: AuthService, private team: TeamService, private staff: EmployeesService) {
    this.backupForm = this.formBuilder.group({
      dateRange: this.formBuilder.group({
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
      }),
      areaBackupArray: this.formBuilder.array([])
    });

    this.auth.userInfoLogged.subscribe((res) => {
      this.userInfoLogged = res;
      this.team.getTeamsByLeader(this.userInfoLogged.idEmployee).subscribe(res=>{
        if(res){
          this.area = res.map((team) => {
            return { id: team._id, area: team.name}
          });
          this.initializeAreaBackupControls();
        }
      })
    })

    const idCompany = sessionStorage.getItem('idCompanySelected');
    this.staff.getAllCompanyEmployees(idCompany).subscribe(res=>{
      if(res){
        this.employee = res.map((employee) => {
          return {
            id: employee?._id,
            area: employee?.area,
            employee: `${employee?.names} ${employee?.surnames}`,
            avatar: employee?.image,
          }
        })
      }
    })
    
    // console.log(this.areaBackupArray.controls)
  }

  private initializeAreaBackupControls() {
    const areaBackupArray = this.backupForm.get('areaBackupArray') as FormArray;
    this.area.forEach(area => {
      areaBackupArray.push(
        this.formBuilder.group({
          area: [area.area],
          backup: [null, Validators.required]
        })
      );
    });
  }

  get areaBackupArray() {
    return this.backupForm.get('areaBackupArray') as FormArray;
  }

  public get startDate(): string | null {
    return this.backupForm.get('dateRange')?.get('startDate')?.value;
  }

  onSubmit() {
    if (this.backupForm.valid) {
      Swal.fire(Swal2Loader)
      setTimeout(() => {
          Swal.close();
          this.dialog.open(AlertComponent, {
            width: '464px',
            data: {
              icon: 'status_approved',
              title: '¡Éxito!',
              description: 'Tus backups ha sido asignados según el periodo que especificaste. No olvides notificar al colaborador.',
              close: 'Aceptar',
            } as AlertDialogData,
          });
          this.router.navigateByUrl('/hours-teams');
      }, 1000);
    }
  }

  public cancelBackup() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_question',
        warn: false,
        title: '¿Estás seguro?',
        description: 'Al cancelar el registro de esta Backup, no podrás guardar los cambios.',
        close: 'Salir sin guardar',
        confirm: 'Seguir editando'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.router.navigateByUrl('/hours-teams');
      }
    });
  }
}

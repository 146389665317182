<!-- <div class="container my-4" *ngIf="this.userInfoLogged.role === 'admin' || this.userInfoLogged.role === 'root'" > -->
<div class="container my-4">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="txt-dark fs-30 fsm-16">Administración de empresas</p>
                </div>
            </div>
            <div class="col-12 d-block d-lg-none text-center fs-12 disabled-text pb-1 pt-4">
                <img alt="Solo lectura" class="mb-2" width="20" height="20" src="../../../../assets/icons/lock.svg">
                <p class="pb-0 mb-0">Datos de lectura.</p>
                <p>Esta sección puede ser editable en una computadora.</p>
            </div>
            <div class="row">
                <div class="col-xs-12 col-lg-9" *ngIf="unableActions">
                    <div class="input-group position-relative">
                        <input type="text" class="form-control" height="30" placeholder="Buscar" [ngModel]="searchFilter" (ngModelChange)="searchFilterCompany($event)">
                        <div class="magni">
                            <!-- <svg width="18px" height="18px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="search" stroke="none" stroke-width="1" fill="#003F9B" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M12.35875,28.632 C7.87575,24.148 7.87575,16.852 12.35875,12.368 C14.53175,10.196 17.41975,9 20.49075,9 C23.56275,9 26.45075,10.196 28.62275,12.368 L28.62275,12.368 C30.79475,14.54 31.99075,17.428 31.99075,20.5 C31.99075,23.571 30.79475,26.46 28.62275,28.632 C26.45075,30.804 23.56275,32 20.49075,32 C17.41975,32 14.53175,30.804 12.35875,28.632 Z M47.36075,42.42 L35.80975,30.87 C37.87575,27.833 38.99075,24.253 38.99075,20.5 C38.99075,15.558 37.06675,10.912 33.57175,7.419 C30.07875,3.924 25.43175,2 20.49075,2 C15.54875,2 10.90375,3.924 7.40975,7.419 C0.19675,14.632 0.19675,26.368 7.40975,33.582 C10.90375,37.076 15.54875,39 20.49075,39 C24.24375,39 27.82375,37.884 30.86075,35.819 L42.41175,47.37 C43.09475,48.053 43.99075,48.395 44.88575,48.395 C45.78175,48.395 46.67775,48.053 47.36075,47.37 C48.72775,46.003 48.72775,43.787 47.36075,42.42 Z" id="Stroke-1" stroke="#003F9B" stroke-width="2"></path>
                            </g>
                        </svg> -->
                        <span class="icon-search"></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12" *ngIf="!unableActions">
                    <div class="input-group position-relative">
                        <input type="text" class="form-control" height="30" placeholder="Buscar" [ngModel]="searchFilter" (ngModelChange)="searchFilterCompany($event)">
                        <div class="magni">
                             <svg width="18px" height="18px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g id="search" stroke="none" stroke-width="1" fill="#003F9B" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <path d="M12.35875,28.632 C7.87575,24.148 7.87575,16.852 12.35875,12.368 C14.53175,10.196 17.41975,9 20.49075,9 C23.56275,9 26.45075,10.196 28.62275,12.368 L28.62275,12.368 C30.79475,14.54 31.99075,17.428 31.99075,20.5 C31.99075,23.571 30.79475,26.46 28.62275,28.632 C26.45075,30.804 23.56275,32 20.49075,32 C17.41975,32 14.53175,30.804 12.35875,28.632 Z M47.36075,42.42 L35.80975,30.87 C37.87575,27.833 38.99075,24.253 38.99075,20.5 C38.99075,15.558 37.06675,10.912 33.57175,7.419 C30.07875,3.924 25.43175,2 20.49075,2 C15.54875,2 10.90375,3.924 7.40975,7.419 C0.19675,14.632 0.19675,26.368 7.40975,33.582 C10.90375,37.076 15.54875,39 20.49075,39 C24.24375,39 27.82375,37.884 30.86075,35.819 L42.41175,47.37 C43.09475,48.053 43.99075,48.395 44.88575,48.395 C45.78175,48.395 46.67775,48.053 47.36075,47.37 C48.72775,46.003 48.72775,43.787 47.36075,42.42 Z" id="Stroke-1" stroke="#003F9B" stroke-width="2"></path>
                          </g>
                      </svg>
                            <span class="icon-search"></span>
                        </div>
                    </div>
                </div> -->

            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <p class="txt-dark fs-13 fw-bold">INFORMACIÓN DE TU CUENTA</p>
                </div>
            </div>

            <div class="row txt-darkgrey fs-12">
                <div class="col-xs-12 col-lg-6">
                    <div class="row px-0">
                        <div class="col-3">
                            <p class="fw-bold mb-0">Tipo de licencia:</p>
                        </div>
                        <div class="col-3">
                            <!-- <p class="mb-0">{{ licenseName }}</p> -->
                            <p>Uniempresa</p>
                        </div>
                    </div>
                    <!-- <div class="row px-0">
                        <div class="col-6 fw-bold">
                            <p class="mb-0">Meses de contratación:</p>
                        </div>
                        <div class="col-6">
                             <p class="mb-0">{{ licenseContractMonths }}</p>
                        </div>
                    </div> -->
                </div>

                <!-- <div class="col-xs-12 col-lg-6 text-right">

                        <button type="button" class="btn btn-secondary-custom btn-secondary fs-12 p-0" data-toggle="modal" data-target="#addCompanyModal" (click)="resetForm()" id="buttonAddCompany">
                        <span class="icon-add"></span> Agregar empresa
                        </button>

                </div> -->
            </div>


            <div class="row">


                <ul class="col-12 list-group list-group-flush text-left mb-3 mt-4 pr-0">
                    <li class="list-group-item pb-1  fw-bold fs-14 text-color">
                        <div class="row">
                            <div class="col-2">Logo</div>
                            <div class="col-10 col-lg-8">Empresa</div>
                        </div>
                    </li>
                </ul>
                <ul class="col-12 list-group list-group-flush container-items text-left custom-scroll pr-0">
                    <li class="list-group-item fs-12 txt-darkgrey" *ngFor="let company of companiesArray; index as i">
                        <div class="row ">
                            <div class="col-2">
                                <img [src]="company.logo ? (company.logo | imagePath: 'profilePicture') : 'assets/img/person.svg'" alt="User" class="user-image rounded-circle ">
                            </div>
                            <div class="col-10 col-lg-8 fw-bold align-self-center">{{ company.companyName }}</div>
                        </div>
                    </li>
                </ul>
            </div>


            <!-- Modal -->
            <div class="modal fade" id="addCompanyModal" tabindex="-1" role="dialog" aria-labelledby="addCompanyModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title fs-14 fw-bold txt-dark text-center pl-3" id="addCompanyModalLabel">Agregar empresa</p>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid fs-12">
                                <form [formGroup]="addCompanyForm" #formAddCompany="ngForm" (ngSubmit)="addCompany(formAddCompany.value)">
                                    <div class="form-group">
                                        <label for="companyName" class="txt-dark">Nombre*</label>
                                        <input type="text" class="form-control" id="companyName" formControlName="companyName" placeholder="Ingresa nombre" maxlength="100" [ngClass]="addCompanyForm.controls['companyName'].invalid && addCompanyForm.controls['companyName'].touched ? 'input-error' : '' ">
                                        <div class="alert-invalid">
                                            <span *ngIf="addCompanyForm.controls['companyName'].invalid && addCompanyForm.controls['companyName'].touched" class="text-red small">
                            Campo obligatorio
                        </span>
                                        </div>
                                    </div>
                                    <div class="form-group pt-1">
                                        <label class="txt-dark">Subir logo de la empresa</label>
                                        <p class="txt-grey mb-0">Formatos permitidos: .jpeg .jpg .png </p>
                                        <p class="txt-grey mb-0">Tamaño máximo: 20 MB </p>
                                        <p class="txt-grey">Recomendado: 800x800 px </p>
                                    </div>

                                    <div class="image-container cr-pointer d-flex justify-content-center">
                                        <div *ngIf="!logo" class="text-container" (click)="file.click()">
                                            <span class="text-center fs-12 fw-bold txt-grey">
                                <img  alt="Logo" class="img photo-ico" width="25" height="25px" src="../../../../assets/icons/archive_gray.svg"><br><br>
                                Arrastra el logo de la empresa aquí<br>o<br>Elige una imagen de tu computadora
                            </span>
                                        </div>
                                        <img *ngIf="logo" [src]="logo ? logo : '' " height="144" alt="Logo" (click)="file.click()">
                                        <input #file id="file-1" type="file" class="file p-5 d-none" accept=".jpg, .jpeg, .png" accept=".jpg, .jpeg, .png" formControlName="logo" (change)="uploadImage(file.files)" />
                                    </div>

                                    <div class="modal-footer pr-0 mr-0" >
                                        <button type="button" class="btn btn-secondary fs-14" data-dismiss="modal" id="cancelAddCompany">Cancelar</button>
                                        <button type="submit" class="btn btn-accept btn-primary  fs-14" [disabled]="!addCompanyForm.valid">Aceptar</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- para evitar residuos cuando lo saquen de sesión -->
                <span #dismisseModal class="hide" data-dismiss="modal"></span>
            </div>
        </div>
    </div>
</div>

<!-- <ng-container *ngIf="userInfoLogged?.role == 'employee'">
  <div class="bg-talents">
    <div class="row">
      <div class="col-12">
        <p class="text">La licencia ha expirado</p>
        <p class="text1">Ponte en contacto con el área de Capital Humano <br class="d-none d-lg-block d-md-block"> para que puedas volver a navegar en la plataforma.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-item-center">
        <img src="../../../../assets/img/home_licencia vencida.png" alt="" class="img-expired-license">
      </div>
    </div>
  </div>
</ng-container> -->

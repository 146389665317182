
  <div class="sidebar" [class.mobile-open]="auth.sideBarToggled | async">
    <ul class="sidebar-nav list-none nav-stacked p-0" id="menu">
      <li class="nav-item d-none d-md-flex"> 
          <span class="icon time-icon-menu_main"></span>
      </li>

      <ng-template 
        *ngIf="viewItem(comunicationContext.$implicit.key)"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="comunicationContext"></ng-template>

      <ng-template 
        *ngIf="viewItem(saludLaboralContext.$implicit.key)"
        [ngTemplateOutlet]="subMenuItem"
        [ngTemplateOutletContext]="saludLaboralContext"></ng-template>

      <!--
      <li *ngIf="viewItem('comunicacion')" (click)="closeSidebarMobile()">
        <a class="d-flex align-items-center white-back" [class.active]="activeLink('menu','comunicacion')"
          [routerLink]="['/posts/menu/list']">
          <!-- <span class="icon-comunicacion3x fs-21"></span> -- >
          <img src="../../../assets/icons/sidebar/menu_comunication.svg" alt="">
          <div>Comunicación</div>
        </a>
      </li>

      <li *ngIf="viewItem('saludLaboral')">
        <a class="d-flex align-items-center" [class.active]="activeLink('menu', 'occupational_healt')"
          [ngClass]="{ 'open':menuOccupational_healt, 'closed': !menuOccupational_healt }" id="occupational_healt"
          (click)="openSubMenu('occupational_healt')">
          <span class="fa-lg d-flex align-items-center">
            <span class="time-icon-menu_health icon"></span>
          </span>
          <div class="d-flex justify-content-between align-items-center w-100">
            Salud laboral
            <i class="fa fa-angle-down arrow" [class.arrow--espanded]="menuOccupational_healt"></i>
          </div>
        </a>
        <ul class="nav-pills nav-stacked sub-menu" [class.show]="menuOccupational_healt">
          <li class="collapsable-item" [class.active]="activeLink('submenu', 'climaLaboral')"
            *ngIf="viewItem('climaLaboral', 'saludLaboral')" (click)="closeSidebarMobile()">
            <a [routerLink]="['/work/work']">Clima laboral</a>
          </li>

          <li class="collapsable-item" [class.active]="activeLink('submenu', 'nom35')" (click)="closeSidebarMobile()">
            <a [routerLink]="[this.nom35Route]">NOM-035</a>
          </li>

          <li class="collapsable-item" [class.active]="activeLink('submenu','buzon')" *ngIf="
              viewItem('buzon', 'saludLaboral')" (click)="closeSidebarMobile()">
            <a *ngIf="this.userInfoLogged.role !== 'employee'" [routerLink]="[
                this.userInfoLogged.role !== 'employee' ? '/box/list' : '/comment']">Buzón 2BCORE</a>
            <a *ngIf="this.userInfoLogged.role === 'employee'" (click)="sendComplain()">Buzón 2BCORE</a>
          </li>
        </ul>
      </li>
    -->


      <ng-template 
          *ngIf="viewItem(gestionTalentoContext.$implicit.key) && this.userInfoLogged.role && this.userInfoLogged.role !== 'employee'"
          [ngTemplateOutlet]="subMenuItem"
          [ngTemplateOutletContext]="gestionTalentoContext"></ng-template>

      <!--
      <li *ngIf="viewItem('gestionTalento') && this.userInfoLogged.role !== 'employee'">
        <a class="d-flex align-items-center  " [class.active]="activeLink('menu', 'talent-menu')"
          [ngClass]="{ 'open':menuTalent, 'closed': !menuTalent }" id="talent-menu"
          (click)="openSubMenu('talent-menu')">
          <span class="icon-gestion-de-talento3x fs-21">
          </span>
          <div class="d-flex justify-content-between align-items-center w-100">
            Gestión de talento
            <i class="fa fa-angle-down arrow"></i>
          </div>
        </a>
        <ul class="nav-pills nav-stacked sub-menu" [class.show]="menuTalent">
          <li class="collapsable-item" [class.active]="activeLink('submenu','gestionTalento')"
            *ngIf="viewItem('expediente','gestionTalento')" (click)="closeSidebarMobile()">
            <a [routerLink]="['/employees/list']">Expediente</a>
          </li>
        </ul>
      </li>
      -->

      <ng-template 
        *ngIf="viewItem(inviteContext.$implicit.key)"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="inviteContext"></ng-template>

      <ng-template 
        *ngIf="['pmo', 'commercial', 'employee','proyectLeader'].includes(this.tmRole)"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="registerHoursContext"></ng-template>
    
      <!--
      <li *ngIf="viewItem('invitaciones')" (click)="closeSidebarMobile()">
        <a class="d-flex align-items-center  " [class.active]="activeLink('menu','invitaciones')"
          [routerLink]="['/invite/events']">
          <span class="fa-lg d-flex align-items-center">
            <span class="time-icon-menu_invitations icon"></span>
          </span>
          <div>Invitaciones</div>
        </a>
      </li>
      <li (click)="closeSidebarMobile()"   *ngIf="this.tmRole !== 'leader'">
        <a class="d-flex align-items-center  " routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
          [routerLink]="['/hours-employee']">
          <span class="time-icon-menu_time icon"></span>
          <div> {{ (this.tmRole === 'commercial' || this.tmRole === 'pmo') ? 'Registro de horas' : 'Gestión del tiempo'}}</div>
        </a>
      </li>
-->

      <ng-template 
        *ngIf="viewItem('gestionTalento') && ['leader',].includes(this.tmRole)"
        [ngTemplateOutlet]="subMenuItem"
        [ngTemplateOutletContext]="registerContext"></ng-template>

        <!-- 
      <li *ngIf="viewItem('gestionTalento') && this.tmRole === 'leader'">
        <a class="d-flex align-items-center  " [class.active]="activeLink('menu', 'register')"
          [ngClass]="{ 'open':menuRegister, 'closed': !menuRegister }" id="register" (click)="openSubMenu('register')">
          <span class="time-icon-menu_time icon"></span>
          <div class="d-flex justify-content-between align-items-center w-100">
            Gestión del tiempo
            <i class="fa fa-angle-down arrow"></i>
          </div>
        </a>
        <ul class="nav-pills nav-stacked sub-menu" [class.show]="menuRegister">
          <li class="collapsable-item" [class.active]="activeLink('submenu','my-register')"
            *ngIf="viewItem('myRegister','register')" (click)="closeSidebarMobile()">
            <a [routerLink]="['/hours-employee']">Mi registro</a>
          </li>
          <li class="collapsable-item" [class.active]="activeLink('submenu','team-register')"
            *ngIf="viewItem('teamRegister','register') && this.tmRole === 'leader'"
            (click)="closeSidebarMobile()">
            <a [routerLink]="['/hours-teams']">Equipos</a>
          </li>
        </ul>
      </li>
      -->

      <ng-template 
        *ngIf="this.tmRole === 'pmo'"
        [ngTemplateOutlet]="menuItemRouterLink"
        [ngTemplateOutletContext]="teamsContext"></ng-template>

      <ng-template 
        *ngIf="['pmo', 'proyectLeader'].includes(this.tmRole)"
        [ngTemplateOutlet]="menuItemRouterLink"
        [ngTemplateOutletContext]="projectsContext"></ng-template>
  
      <ng-template 
        *ngIf="this.tmRole === 'commercial'"
        [ngTemplateOutlet]="menuItemRouterLink"
        [ngTemplateOutletContext]="commercialContext"></ng-template>

        <!-- 
      <li (click)="closeSidebarMobile()" *ngIf="this.tmRole === 'pmo'">
        <a class="d-flex align-items-center  " routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
          [routerLink]="['/teams']">
          <span class="fa-lg d-flex align-items-center">
             <span class="time-icon-menu_team icon"></span>
          </span>
          <div>Equipos</div>
        </a>
      </li>
      <li (click)="closeSidebarMobile()" *ngIf="this.tmRole === 'pmo'  || this.tmRole === 'proyectLeader'">
        <a class="d-flex align-items-center  " routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
          [routerLink]="['/projects']">
          <span class="fa-lg d-flex align-items-center">
             <span class="time-icon-Report icon"></span>
          </span>
          <div>{{this.tmRole === 'pmo' ? 'Proyectos y asignaciones' : 'Proyectos'}}</div>
        </a>
      </li>
      <li (click)="closeSidebarMobile()" *ngIf="this.tmRole === 'commercial'">
        <a class="d-flex align-items-center  " routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
          [routerLink]="['/comercial']">
          <span class="fa-lg d-flex align-items-center">
             <span class="time-icon-Report icon"></span>
          </span>
          <div>Oportunidades</div>
        </a>
      </li>
      -->

    </ul>
  </div>

<ng-template #subMenuItem let-item>
  <li>
    <div class="nav-item"
        [class.active]="activeLink('menu', item.key)"
        (click)="openSubMenu(item.key)">
      <span [class]="'icon ' + item.icon"></span>
      <div class="d-flex justify-content-between align-items-center w-100">
        <span class="label text-truncate"> {{ item.label }}</span>
        <span class="time-icon-arrow_collapse arrow" [class.arrow--espanded]="item.isOpen"></span>
      </div>
    </div>
    
    <ul class="list-none nav-stacked sub-menu" [class.show]="item.isOpen">
      <ng-container *ngFor="let item of item.submenu">
        <ng-template 
          [ngTemplateOutlet]="menuItem"
          [ngTemplateOutletContext]="{$implicit: item, level: 'submenu'}"></ng-template>
      </ng-container>
    </ul>
  </li>
</ng-template>

<ng-template #menuItem let-item let-level="level">
  <li 
    class="nav-item"
    [class.active]="activeLink(level, item.key)"
    [routerLink]="[item.route]" 
    [queryParams]="item?.queryParams"
    (click)="closeSidebarMobile()">
      <span [class]="'icon ' + item.icon"></span>
      <span class="label text-truncate"> {{ item.label }}</span>
  </li>
</ng-template>

<ng-template #menuItemRouterLink let-item>
  <li 
    class="nav-item"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: false}"
    [routerLink]="[item.route]" 
    (click)="closeSidebarMobile()">
      <span [class]="'icon ' + item.icon"></span>
      <span class="label text-truncate"> {{ item.label }}</span>
  </li>
</ng-template>

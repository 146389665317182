import { Component, Input } from '@angular/core';
import { Risk } from '../project.const';

@Component({
  selector: 'app-risks[risks]',
  templateUrl: './risks.component.html',
  styleUrls: ['./risks.component.scss']
})
export class RisksComponent {
  @Input() risks!: Risk[];
  public displayedColumns: string[] = ['title', 'status'];

  // Mobile
  public currentCard = 0;
  public card: Risk | null = null;
  
  ngOnInit() {
    if (this.risks && this.currentCard in this.risks) {
      this.card = this.risks[this.currentCard];
    } else {
      this.card = null; // O algún valor por defecto
    }
  }


  public changeCard(val: 1 | -1) {
    const indexCard = val + this.currentCard;
    const card = this.risks[indexCard];
    if (card) { 
      this.currentCard = indexCard;
      this.card = card;
    }
  }
}

export interface Breadcrumb {
    title: string
    route: string
}

export const CRUMBS = {
    pmoTeams: {
        title: 'Equipos',
        route: '/teams',
    },
    pmoNewTeam: {
        title: 'Agregar equipo',
        route: '/teams/new-team',
    },
    pmoUpdateTeam: {
        title: 'Editar equipo',
        route: '/teams/update-team',
    },
    pmoProjects: {
        title: 'Proyectos y asignaciones',
        route: '/projects',
    },
    proyectLeader: {
        title: 'Proyectos',
        route: '/projects',
    },
    pmoProject: {
        title: 'Proyecto',
        route: '/projects/project',
    },
    colaboratorRegisterActivities: {
        title: 'Registro de horas',
        route: '/hours-employee',
    },
    colaboratorRegisterActivity: {
        title: 'Nueva actividad',
        route: '/register',
    },
    colaboratorUpdateActivity: {
        title: 'Editar actividad',
        route: '/register',
    },
    colaboratorHistory: {
        title: 'Historial',
        route: '/record',
    },
    areaLeadMain: {
        title: 'Mis equipos',
        route: '/hours-teams'
    },
    colaboratorDetails: {
        title: 'Detalles de actividad',
        route: '/collaborator/details',
    },
    areaLeadBackup: {
        title: 'Asignar Backup',
        route: '/hours-teams/backup'
    },
    areaLeadEmmployeeDetails: {
        title: 'Acerca del colaborador',
        route: '/hours-teams/employee-detail'
    },
    oportunityManage: {
        title: 'Gestión de oportunidades',
        route: '/comercial'
    },
}

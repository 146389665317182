import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertComponent, AlertDialogData } from '@components/alert/alert.component';
import { LogService } from 'src/app/services/log/log.service';
import { Incidence, INCIDENCES } from './incidences.const';

export interface DialogData {
  employeeId: string
  employeeName: string
}


@Component({
  selector: 'app-incidence',
  templateUrl: './incidence.component.html',
  styleUrls: ['./incidence.component.scss']
})
export class IncidenceComponent {
  public incidenceForm!: FormGroup;
  public selectedIncidence: string = '';
  public readonly today = new Date();
  private readonly locale = 'es-Mx';
  private readonly timeShortConfig: Intl.DateTimeFormatOptions = {
    day: 'numeric', month: 'numeric', year: 'numeric'
  };
  public readonly incidence: Incidence[] = INCIDENCES;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<IncidenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public log: LogService
  ) { }

  ngOnInit(): void {
    this.incidenceForm = this.formBuilder.group({
      incidence: [null, Validators.required
      ],
      startDate: [''],
      endDate: [''],
      hours: [''],
    });


    this.incidenceForm.get('incidence')?.valueChanges.subscribe((value: string) => {
      // console.log('Selected Incidence: ', value);
      this.selectedIncidence = value;

      this.incidenceForm.controls['startDate'].clearValidators();
      this.incidenceForm.controls['endDate'].clearValidators();
      this.incidenceForm.controls['hours'].clearValidators();

      if (value === 'pcgds' || value === 'psgds') {
        this.incidenceForm.controls['startDate'].setValidators(Validators.required);
        this.incidenceForm.controls['hours'].setValidators([Validators.required, Validators.min(1), Validators.max(24)]);
      } else {
        this.incidenceForm.controls['startDate'].setValidators(Validators.required);
        this.incidenceForm.controls['endDate'].setValidators(Validators.required);
      }

      this.incidenceForm.controls['startDate'].updateValueAndValidity();
      this.incidenceForm.controls['endDate'].updateValueAndValidity();
      this.incidenceForm.controls['hours'].updateValueAndValidity();
    });
  }

  public managementHours(incrementValue: 1 | -1 | 0 = 0) {
    let newValue = Number(this.incidenceForm.controls['hours'].value ?? 0) + incrementValue;

    if (newValue < 0) return;

    if (newValue >= 24) {
      newValue = 24;
    }
    this.incidenceForm.controls['hours'].setValue(newValue);
  }

  onSubmit() {
    let alertData: AlertDialogData;
    const formValue = this.incidenceForm.value;
    const data={
      collaborator: this.data.employeeId,
      date: formValue.startDate,
      time: formValue.incidence,
      hours: formValue.hours ? formValue.hours : 0,
      minutes: 0,
      status: 'approved',
    }
    formValue.endDate ? data['endDate'] = formValue.endDate : ''
    console.log("🚀 ~ IncidenceComponent ~ onSubmit ~ formValue:", formValue)

    this.log.createLog(data).subscribe(res=>{
      if(res){
        const incidence = this.incidence.find((incidence) => incidence.value == formValue.incidence);
        alertData = {
          icon: 'status_approved',
          title: '¡Éxito!',
          description: `La incidencia se cargó correctamente. \n
          Colaborador: ${ this.data.employeeName }
          Incidencia: ${ incidence?.viewValue }\n`,
          close: 'Aceptar',
        }
        alertData.description += formValue?.startDate && formValue?.endDate
        ? `Fecha de inicio: ${formValue.startDate.toLocaleString(this.locale, this.timeShortConfig)} \n Fecha de fin: ${formValue.endDate.toLocaleString(this.locale, this.timeShortConfig)}`
        : `Fecha: ${formValue.startDate.toLocaleString(this.locale, this.timeShortConfig)} \nHoras: ${formValue?.hours ?? 0}`;
        this.dialogRef.close(data);
      }else{
        alertData = {
          icon: 'status_rejected',
          title: '¡Error!',
          description: 'No ha sido posible asignar cargar la incidencia. Inténtalo de nuevo.',
          close: 'Aceptar',
        }
      }
      this.dialog.open(AlertComponent, {
        width: '464px',
        data: alertData
      });
    });

  }

}

import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { activities, Activity, ActivitiesPerMonth } from '../report/report.const';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {
  public employeeActivities: ActivitiesPerMonth | undefined;
  public activityDetail: Activity | undefined;
  public employeeId: string;
  public employeeName: string;
  public today: Date;
  public dateFormat: string = 'longDate';
  public status: string;
  public showSuccessModal: boolean = false;
  public showDeclineModal: boolean = false;
  public showSendModal: boolean = false;

  constructor(private route: ActivatedRoute) {
    const activityId = this.route.snapshot.paramMap.get('activityId');
    this.employeeId = this.route.snapshot.paramMap.get('employeeId');

    if (this.employeeId && activityId) {
      const employee = activities.find(emp => emp.employeeId === this.employeeId);
      this.employeeName = employee?.employee;
      this.activityDetail = employee?.activities.find(activity => activity.activityId === activityId);
    }


    this.today = new Date();
    this.updateDateFormat(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateDateFormat(event.target.innerWidth);
  }

  private updateDateFormat(width: number): void {
    this.dateFormat = width <= 768 ? 'dd/MM/yyyy' : 'longDate';
  }

  openModal(type: string) {
    if (type === 'success') {
      this.showSuccessModal = true;
    } else if (type === 'decline') {
      this.showDeclineModal = true;
    } else if (type === 'send') {
      this.showSendModal = true;
    }
  }

  closeModal(type: string) {
    if (type === 'success') {
      this.showSuccessModal = false;
    } else if (type === 'decline') {
      this.showDeclineModal = false;
    } else if (type === 'send') {
      this.showSendModal = false;
    }
  }

  onSubmit() {
    this.status = 'loading';
    this.closeModal('decline');

    setTimeout(() => {
      if (Math.random() < 0.5) { // Mock success/error randomly
        this.status = 'success';
        this.openModal('send');
      } else {
        this.status = 'error';
        this.openModal('error');
      }
      console.log(this.status)
    }, 2000);
  }
}

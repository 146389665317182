<div class="container w-90">
    <div class="row">
        <div class="col-12 d-md-none">
            <a class="text-decoration-none" [routerLink]="'/hours-teams'">
                <span class="time-icon-back fs-16"></span>
            </a>
        </div>
        <div class="col-12 d-none d-md-block">
            <app-breadcrumb [crumbs]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="col-12 d-flex justify-content-between mt-3 mt-md-4">
            <p class="title mb-0">Acerca del colaborador</p>
            <div class="d-md-flex d-none">
                <button class="btn-secondary mr-1" (click)="openIncidenceDialog()">Cargar incidencia</button>
                <button class="btn-primary ml-1" [routerLink]="['/hours-teams/report', employeeId]">Ver registro de horas</button>
            </div>
        </div>
    </div>
    <div class="row mt-4 mt-md-5">
        <div class="col-md-4 col-12">
            <div class="emp-card">
                <div class="header">
                    <img class="avatar-100" [src]="employeeInfo?.image ? (employeeInfo.image | imagePath: 'profilePicture') :'assets/img/person.svg'" alt="Profile">
                </div>
                <div class="info text-center">
                    <h2>{{employeeInfo?.names}} {{employeeInfo?.surnames}}</h2>
                    <p>{{employeeInfo?.marketStall}}</p>
                    <h2>No. de empleado</h2>
                    <p>{{employeeInfo?.employeeNumber}}</p>
                    <h2>Área / Equipo</h2>
                    <p>{{employeeInfo?.area}}</p>
                </div>
            </div>
        </div>
        <div class="col-12 d-block d-md-none my-4">
            <button class="btn-secondary mb-2" (click)="openIncidenceDialog()">Cargar incidencia</button>
            <button class="btn-primary" [routerLink]="['/hours-teams/report', employeeId]">Ver registro de horas</button>
        </div>
        <div class="col-md-8 col-12">
            <mat-accordion multi class="deep-accordion" hideToggle="true">
                <mat-expansion-panel expanded #projectsTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Proyectos
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="projectsTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="panel" *ngFor="let project of projects">
                        <h3 class="title">{{project.name}}</h3>
                        <p class="value">{{project.category}}</p>
                        <p class="value mt-4">Cliente: <b>{{project.client}}</b></p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #othersTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Otras actividades
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="othersTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="panel" *ngFor="let activities of otherprojects">
                        <h3 class="title">{{activities.name}}</h3>
                        <p class="value">{{activities.code}}</p>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #incidencesTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Incidencias
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="incidencesTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="panel" *ngFor="let incidence of incidences">
                        <h3 class="title mb-4">{{incidence.time}}</h3>
                        <p class="value">Fecha de inicio: {{incidence.date | date}}</p>
                        <p class="value" *ngIf="incidence.endDate">Fecha fin: {{incidence.endDate | date}}</p>
                        <p class="value" *ngIf="incidence.hours">Horas: {{incidence.hours}}</p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { Task } from '../project.const';

@Component({
  selector: 'app-additional-tasks[additionalTasks]',
  templateUrl: './additional-tasks.component.html',
  styleUrls: ['./additional-tasks.component.scss']
})
export class AdditionalTasksComponent {
  @Input() additionalTasks!: Task[];
  public displayedColumns: string[] = ['title', 'status'];

  // Mobile
  public currentCard = 0;
  public card: Task | null = null;

  ngOnInit() {
    if (this.additionalTasks && this.currentCard in this.additionalTasks) {
      this.card = this.additionalTasks[this.currentCard];
    } else {
      this.card = null;
    }
    
  }

  public changeCard(val: 1 | -1) {
    const indexCard = val + this.currentCard;
    const card = this.additionalTasks[indexCard];
    if (card) { 
      this.currentCard = indexCard;
      this.card = card;
    }
  }

}

import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Overview } from '../project.const';
import { Employee } from '@pmo/teams/team/team.const';
import { EmployeesService } from 'src/app/services';

@Component({
  selector: 'app-overview[overview][allEmployees]',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  @Input() overview!: Overview;
  @Input() allEmployees: Employee[] = [];
  @Output() downloadReportEvent = new EventEmitter<boolean>();
  userByCompany:any;
  resp2bc:any;
  public downloadReport() {
    this.downloadReportEvent.emit(true);
  }
  constructor(
      private employeeService: EmployeesService,
  ) {
  
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    this
    if (changes['overview'] && changes['overview'].currentValue) {
      const idCompany = sessionStorage.getItem('idCompanySelected');
      this.employeeService.getAllCompanyEmployees(idCompany).subscribe((users) => {
        this.userByCompany = users;
        this.userByCompany.forEach((user: any) => {
          const addUser = {
            name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
            image: user.image,
            _id: user.idUser
          }
          if (this.overview.leaderAvatar === user.idUser) {
            this.resp2bc = addUser;
          }
        });
      });
    }
  }
}

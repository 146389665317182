export interface Incidence {
  value: string;
  viewValue: string;
}
export const INCIDENCES: Incidence[] = [
    { value: 'asueto', viewValue: 'Asueto' },
    { value: 'baja', viewValue: 'Baja' },
    { value: 'falta', viewValue: 'Falta' },
    { value: 'incapacidad', viewValue: 'Incapacidad' },
    { value: 'pcgds', viewValue: 'Permiso con goce de sueldo' },
    { value: 'psgds', viewValue: 'Permiso sin goce de sueldo' },
    { value: 'vacaciones', viewValue: 'Vacaciones' }
  ];

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { activities, Activity } from './report.const';
import { ActivitiesPerMonth } from './report.const';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { MatDatepicker } from '@angular/material/datepicker';
import { eachWeekendOfMonth, getDaysInMonth, isSameMonth } from 'date-fns';
import { IncidenceComponent } from '../incidence/incidence.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '@components/alert/alert.component';
import { RejectDialogComponent } from '../reject-dialog/reject-dialog.component';
import { LogService } from 'src/app/services/log/log.service';
import { TeamService } from 'src/app/services/team/team.service';
import { INCIDENCES } from '../incidence/incidences.const';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  public monthActivities: any;
  public selected: Date = new Date();
  employeeId;
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public totalPages: number = 0;
  public activeIndex: number | null = null;
  private readonly HOURS_PER_DAY = 8;
  public readonly TODAY = new Date();
  public readonly START_DATE = new Date('2022-1-1');
  public readonly breadcrumb: Breadcrumb[] = [ CRUMBS.areaLeadMain ];
  public chartData = {
    approved: {
      hours: 0,
      percentage: 0,
    },
    pending: {
      hours: 0,
      percentage: 0,
    },
    rejected: {
      hours: 0,
      percentage: 0,
    },
    empty: {
      hours: 0,
      percentage: 0,
    },
  }
  public employeeName = '';
  private readonly pdfMargin = 15; // mm
  private readonly shortDateFormat: Intl.DateTimeFormatOptions = {
     day: '2-digit', month: '2-digit', year: 'numeric'
  };

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private log: LogService, private teamService: TeamService) { }

  ngOnInit(): void {
    this.employeeId = this.route.snapshot.paramMap.get('id');

    this.filterValues();

    this.teamService.getEmployeeInfo(this.employeeId).subscribe(res=>{
      this.employeeName = `${res.employee.names} ${res.employee.surnames}`;

      const reportCrumb = {
        title: this.employeeName,
        route: `/hours-teams/report/${this.employeeId}`
      }
      this.breadcrumb.push(reportCrumb)
    })
    
    // this.getInfo()
  }

  onDateSelected(selectedDate: Date): void {
    if (selectedDate) {
      this.selected = selectedDate;
      this.filterValues();
    }
  }

  getInfo(){
    this.log.getReport(this.employeeId, this.selected).subscribe(res=>{
      console.log(res)
    })
  }

  private filterValues() {
    this.log.getReport(this.employeeId, this.selected).subscribe(res=>{
      this.monthActivities = res.log.filter(log => {
        // Verificamos si 'time' NO incluye las palabras a excluir
        const incidence = INCIDENCES.find((incidence) => incidence.value == log.time.toLowerCase());
        return !incidence;
      });
      console.log(this.monthActivities)
      this.updateChartData(res.count.totalHoursApproved, res.count.totalHoursPending, res.count.totalHoursRefused ); // Set initial chart data
  
      this.totalPages = Math.ceil(this.monthActivities?.length / this.itemsPerPage);
    })

    // this.monthActivities = this.employeeActivities.activities.filter(a => {
    //   const activityDate = new Date(a.date);
    //   return isSameMonth(activityDate, this.selected);
    // });

  }

  updateChartData(approvedTime: number, pendingTime: number, rejectedTime: number): void {

    const hoursInMonth = this.getTotalHoursInMonth(this.selected);
    const emptyTime = hoursInMonth - (approvedTime + pendingTime + rejectedTime);

    this.chartData = {
      approved: {
        hours: approvedTime,
        percentage: this.calculatePercentege(approvedTime, hoursInMonth)
      },
      pending: {
        hours: pendingTime,
        percentage: this.calculatePercentege(pendingTime, hoursInMonth)
      },
      rejected: {
        hours: rejectedTime,
        percentage: this.calculatePercentege(rejectedTime, hoursInMonth)
      },
      empty: {
        hours: emptyTime,
        percentage: this.calculatePercentege(emptyTime, hoursInMonth)
      },
    }
  }

  private calculatePercentege(value: number, total: number): number {
    const percentage = (value / total) * 100;
    return +percentage.toFixed(2);
  }

  private getTotalHoursInMonth(month: Date) {
    const daysInMonth = getDaysInMonth(month);
    const weekendDaysOfMonth = eachWeekendOfMonth(month)
    const workingDays = daysInMonth - weekendDaysOfMonth.length;
    return workingDays * this.HOURS_PER_DAY;
  }

  get paginatedActivities() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.monthActivities?.slice(startIndex, endIndex);
  }

  toggleCollapse(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  changePage(page: number): void {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  downloadPDF(): void {
    const doc = new jsPDF('p', 'mm', 'letter');

    const logoSize = 16;
    const img = new Image();
    img.src = 'assets/logo/2BC_logotipo_azul.png';
    doc.addImage(img, 'png', this.pdfMargin, this.pdfMargin, logoSize, logoSize);

    const logoSpace = (this.pdfMargin + logoSize + this.pdfMargin)
    // Title
    doc.setFont('helvetica');
    doc.setFontSize(16);
    doc.setTextColor('#1863d6');
    doc.setFontType('bold');
    const month = this.selected.toLocaleString('default', { month: 'long' });
    const year = this.selected.getFullYear();
    doc.text(`Registro de horas ${month} ${year}`, logoSpace, (this.pdfMargin + 6));

    // Subtitle
    doc.setFontSize(13);
    doc.setTextColor('#01173c');
    doc.text(`${this.employeeName}`, logoSpace, (this.pdfMargin + 14));

    // Table
    const columns = ['Fecha', 'Actividad', 'Tipo de Actividad', 'Tiempo', 'Descripción', 'Estatus'];
    const rows = this.monthActivities.map(activity => {
      const status = activity.status === 'approved' ? 'Aprobada': activity.status === 'rejected' ? 'Rechazada' : 'Pendiente';
      let time = Number(activity?.hours ?? 0) > 0 ? `${activity.hours} h ` : '';
      if (Number(activity?.minutes ?? 0)) {
        time += `${activity.minutes} min`;
      }
      return [
      new Date(activity.date).toLocaleString('es-Mx', this.shortDateFormat),
      activity.time,
      activity.oportunity,
      time,
      activity.activities,
      status 
    ]});

    autoTable(doc, {
      head: [columns],
      body: rows,
      startY: 42,
      theme: 'plain',
      tableLineWidth: 0.3,
      tableLineColor: '#dce8f9',
      styles: {
        lineColor: '#dce8f9',
        lineWidth: {bottom: 0.6},
        cellPadding: 4,
      },
      headStyles: {
        fillColor: '#ffffff',
        textColor: '#1863d6',
        fontSize: 12,
        fontStyle: 'bold',
      },
      bodyStyles: {
        fillColor: '#ffffff',
        textColor: '#01173c',
        fontSize: 11,
      },
    });

    doc.save(`Reporte_${this.employeeName}_${month}_${year}.pdf`);
  }

  public approveActivity(activityId: string) {
    const object = {
      id: activityId,
      body: {
        status: 'approved'
      }
    }
    this.log.updateLogs(object).subscribe(res=>{
      if(res){
        this.dialog.open(AlertComponent, {
          width: '464px',
          data: {
            icon: 'status_approved',
            title: 'Actividad aprobada',
            description: `Has aprobado esta actividad. En breve, le informaremos a ${ this.employeeName } sobre tu aprobación.`,
            close: 'Cerrar',
          }
        });
        this.filterValues()
      }
    })
  }

  openRejectDialog(activityId: string) {
    const dialogRef = this.dialog.open(RejectDialogComponent,
     { data: { employeeId: this.employeeId, employeeName: this.employeeName, activityId }, width: '464px', disableClose: true });
   
     dialogRef.afterClosed().subscribe((result) => {
      this.filterValues()
     });
  }

  setMonthAndYear(normalizedMonthAndYear: Date, datepicker: MatDatepicker<Date>) {
    datepicker.close();
    this.selected = normalizedMonthAndYear;
  }

  public openIncidenceDialog() {
    const dialogRef = this.dialog.open(IncidenceComponent, {
      width: '464px',
      data: {
        employeeId: this.employeeId,
        employeeName: this.employeeName
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }
}

<div class="container">
    <div class="row">
        <div class="col-12 d-md-none">
            <a class="text-decoration-none" [routerLink]="'/projects'">
                <span class="time-icon-back fs-16"></span>
            </a>
        </div>
        <div class="col-12 d-none d-md-block">
            <app-breadcrumb [crumbs]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="col-12 text-right deep-button d-none d-md-block">
            <button class="button-custom-cancel mr-2" [ngClass]=" this.tmRole === 'proyectLeader' ?  'pl-flux mr-3' : ''"
            (click)="downloadReport()">Descargar
            </button>
            <button [routerLink]="'/edit-proyect'" (click)="goToEdit()" class="button-edit"  *ngIf="this.tmRole === 'proyectLeader'">
                <span>Editar</span>
            </button>
        </div>

        <div class="col-12 mt-32">
            <app-overview [overview]="this.configuredData?.overview" [allEmployees]="this.configuredData?.overview?.team" (downloadReportEvent)="downloadReport()" #overviewTemp></app-overview>

            <div class="text-right deep-button mt-4 d-block d-md-none">
                <button mat-flat-button (click)="downloadReport()" color="primary" class="w-100 mb-3">
                    <span>Descargar reporte</span>
                </button>
                <button class="w-100" mat-flat-button  color="primary" [routerLink]="'/edit-proyect'" (click)="goToEdit()"  *ngIf="this.tmRole === 'proyectLeader'">
                    <span>Editar</span>
                </button>
            </div>

            <div class="mt-5"></div>

            <mat-accordion multi class="deep-accordion mt-5" hideToggle="true">
                <mat-expansion-panel expanded #progressTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Progreso
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="progressTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-progress [projection]="this.configuredData?.progress?.projection" [realProgress]="this.configuredData?.progress?.realProgress"></app-progress>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #aboutTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Acerca del proyecto
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="aboutTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-about [about]="this.configuredData?.about"></app-about>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #dependenciesTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Dependencias
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="dependenciesTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-dependencies [dependencies]="configuredData?.dependencies"></app-dependencies>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #workPlanTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Plan de trabajo
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="workPlanTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-work-plan [workPlan]="configuredData?.workPlan"></app-work-plan>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #additionalTasksTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Actividades adicionales
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="additionalTasksTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-additional-tasks [additionalTasks]="configuredData?.additionalTasks"></app-additional-tasks>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #risksTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Riesgos
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="risksTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-risks [risks]="configuredData?.risks"></app-risks>
                </mat-expansion-panel>

                <mat-expansion-panel expanded #commentsTemp>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="justify-content-between">
                            Comentarios
                            <span class="time-icon-paginator_next toggle-icon" [class.toggle-icon--espanded]="commentsTemp._getExpandedState() === 'expanded'"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-comments [comments]="commentsExample"></app-comments>
                </mat-expansion-panel>

            </mat-accordion>
        </div>
    </div>
</div>

<ng-template #loaderTemplate>
    <h1 mat-dialog-title>Generando reporte</h1>
    <div mat-dialog-content>
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<div class="container w-90">
    <div class="row">
        <div class="col-12 mb-4">
            <app-breadcrumb [crumbs]="breadcrumb"></app-breadcrumb>
        </div>

        <div class="col-12 d-flex flex-column flex-md-row justify-content-between">
            <p class="title mb-0">Registro de actividades por Área/Equipo</p>
            <button class="btn-secondary mt-4 mt-md-0" routerLink="/hours-teams/backup">Asignar un Backup</button>
        </div>

        <div class="col-12">
            <div class="d-flex justify-content-between justify-content-md-start align-items-center mt-3 pt-3 pt-md-0">
                <div class="d-flex align-items-center mr-3 flex-column flex-md-row">
                    <span class="time-icon-status_approved fs-24"><span class="path1"></span><span
                            class="path2"></span></span>
                    <p class="title-text mb-0 ml-md-2 mt-3 mt-md-0">Aprobada</p>
                </div>
                <div class="d-flex align-items-center mr-3 flex-column flex-md-row">
                    <span class="time-icon-status_pending fs-24"><span class="path1"></span><span
                            class="path2"></span></span>
                    <p class="title-text mb-0 ml-md-2 mt-3 mt-md-0">Por aprobar</p>
                </div>
                <div class="d-flex align-items-center flex-column flex-md-row">
                    <span class="time-icon-status_rejected fs-24"><span class="path1"></span><span
                            class="path2"></span></span>
                    <p class="title-text mb-0 ml-md-2 mt-3 mt-md-0">Rechazada</p>
                </div>
            </div>
        </div>
    </div>

    <!--div class="row justify-content-end px-3">
        <div class="filter">
            <div class="dropdown">
                <button class="btn dropdown-toggle title-text" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Filtrar
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div class="dropdown-item">
                        <span class="filter-title">Estado</span>
                    </div>
                    <div class="dropdown-item d-flex align-items-center">
                        <input class="mr-2" type="checkbox" id="cbox1" value="" />
                        <label class="mb-0" for="cbox3">Aprobada</label>
                    </div>
                    <div class="dropdown-item d-flex align-items-center">
                        <input class="mr-2" type="checkbox" id="cbox2" value="" />
                        <label class="mb-0" for="cbox4">Por aprobar</label>
                    </div>
                    <div class="dropdown-item d-flex align-items-center">
                        <input class="mr-2" type="checkbox" id="cbox2" value="" />
                        <label class="mb-0" for="cbox5">Rechazada</label>
                    </div>
                </div>
            </div>
        </div>
    </div-->

    <div *ngFor="let team of uniqueTeams" class="mt-5">
        <p class="subtitle">{{team.name}}</p>
        <div class="grid-container">
            <div *ngFor="let employee of team.collaborators" class="card">
                <div>
                    <span class="status-icon" *ngIf="employee.status === 'approved'">
                        <span class="time-icon-status_approved fs-24"><span class="path1"></span><span
                                class="path2"></span></span>
                    </span>
                    <span class="status-icon" *ngIf="employee.status === 'pending'">
                        <span class="time-icon-status_pending fs-24"><span class="path1"></span><span
                                class="path2"></span></span>
                    </span>
                    <span class="status-icon" *ngIf="employee.status === 'rejected'">
                        <span class="time-icon-status_rejected fs-24"><span class="path1"></span><span
                                class="path2"></span></span>
                    </span>

                    <div class="dropleft ml-3">
                        <span class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                            <span class="time-icon-options fs-20"></span>
                        </span>
                        <div class="dropdown-menu">
                            <span class="dropdown-style">
                                <a class="dropdown-item px-2 w-auto cr-pointer" (click)="openIncidenceDialog(employee.id, employee.name)">
                                    <span class="time-icon-calendar_default mr-1"></span>
                                    Cargar incidencia
                                </a>
                                <a class="dropdown-item px-2 w-auto cr-pointer"
                                    [routerLink]="['/hours-teams/employee-detail', employee.id]">
                                    <span class="time-icon-menu_individual mr-1"></span>
                                    Ver detalles
                                </a>
                            </span>
                        </div>
                    </div>
                    <img [src]="team.leader.image ? (team.leader.image | imagePath: 'profilePicture') :'assets/img/person.svg'" class="avatar">
                </div>
                <div class="w-100 mt-4">
                    <h3 class="name text-truncate">{{ employee.name }}</h3>
                    <p class="role text-truncate">{{ employee.position }}</p>
                    <button class="btn btn-primary" [routerLink]="['/hours-teams/report', employee.id]">Ver
                        reporte</button>
                </div>
            </div>
        </div>
    </div>
</div>


<table class="about">
    <tr class="about__row">
        <th class="about__title">Descripción:</th>
        <th class="about__description">{{ about?.description }}</th>
    </tr>
    <tr class="about__row">
        <th class="about__title">Objetivos:</th>
        <th class="about__description">
            <ul class="objetives">
                <li *ngFor="let objetive of about?.objectives" class="objetive">
                    {{objetive}}
                </li>
            </ul>
        </th>
    </tr>
    <tr class="about__row">
        <th class="about__title">Cliente:</th>
        <th class="about__description">{{ about?.client }}</th>
    </tr>
    <tr class="about__row">
        <th class="about__title">Código:</th>
        <th class="about__description">{{ about?.code }}</th>
    </tr>
    <tr class="about__row">
        <th class="about__title">Metodología:</th>
        <th class="about__description">{{ about?.methodology }}</th>
    </tr>
</table>
import { Component, Input, SimpleChanges } from '@angular/core';
import { Comment } from '../project.const';
import { leaders } from '@pmo/teams/team/team.const';
import { LogService } from 'src/app/services/log/log.service';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { EmployeesService } from 'src/app/services';

@Component({
  selector: 'app-comments[comments]',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @Input() comments!: any;
  arrayComments = [];
  user = {
    userAvatar: leaders[5].avatar,
    userName: leaders[5].name,
  }

  comentario = "";
  image;
  employees = []
  constructor(
    private logService: LogService,
    private oportunityService: OportunityService,
    private employeeService: EmployeesService
  ){
  }

  ngOnInit(){
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['comments'] && changes['comments'].currentValue) {
      const idCompany = sessionStorage.getItem('idCompanySelected');
      this.employeeService.getAllCompanyEmployees(idCompany).subscribe(res=>{
        this.employees = res
        this.loadComments();
      })
    }
  }

  

  loadComments(){
    this.arrayComments = [];
    this.comments.comments?.forEach(comment=>{
        const user = this.employees?.filter(employee => {return comment.user === employee._id})
        if(user[0]?._id === this.comments.idUser){
          this.image = user[0].image
        }
        this.arrayComments.push({
          userName: user[0]?.names + ' '+user[0]?.surnames,
          userAvatar: user[0]?.image,
          comment: comment.comment,
          createdAt: comment.date
        })
    })
  }

  emojiAns: boolean = false;

  handleSelectionAnsw(e) {
    this.comentario += e.char;
    this.emojiAns = false;
  }
  handleCharDelete(e) {
    /*
    if (this.msg.length > 0) {
      this.msg = this.msg.substr(0, this.text.length - 1);
    }
      */
  }

  createComment(){
    const comment = [{user: this.comments.idUser, comment: this.comentario, date: new Date()}]
    this.comments.comments.push(comment[0])
    if(this.comments.type === 'activity'){
      this.logService.createComment({id: this.comments.id, body: {comments: comment}}).subscribe(res=>{
        if(res){
          this.loadComments()
        }
      })
    }else if(this.comments.type === 'oportunity'){
      this.oportunityService.createComment({id: this.comments.id, body: {comments: comment}}).subscribe(res=>{
        if(res){
          this.loadComments()
        }
      })
    }
    this.comentario = "";
  }


}

import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableDataSourcePaginator } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Dependency } from '../project.const';
import { Employee } from '@pmo/teams/team/team.const';
import { EmployeesService } from 'src/app/services';

@Component({
  selector: 'app-dependencies[dependencies]',
  templateUrl: './dependencies.component.html',
  styleUrls: ['./dependencies.component.scss']
})
export class DependenciesComponent {
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Input() dependencies: Dependency[] = [];
  public displayedColumns: string[] = ['description', 'createdAt', 'inCharge', 'status', 'commitmentAt'];
  public dependenciesDataSource!: MatTableDataSource<Dependency, MatTableDataSourcePaginator>;
  public currentFilter: 'To Do' | 'WIP' | 'Done' | '' = '';
  userByCompany:any;
  // Mobile
  public currentCard = 0;
  public card: Dependency | null = null;

  constructor(private employeeService: EmployeesService) { }

  ngOnInit() {
   
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dependencies'] && changes['dependencies'].currentValue) {
      
      
  
    this.dependenciesDataSource = new MatTableDataSource(this.dependencies);
    if (this.dependencies && this.currentCard in this.dependencies) {
      const idCompany = sessionStorage.getItem('idCompanySelected');
      this.card = this.dependencies[this.currentCard];
      this.employeeService.getAllCompanyEmployees(idCompany).subscribe((users) => {
        this.userByCompany = users;
        this.userByCompany.forEach((user: any) => {
          const addUser = {
            name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
            image: user.image,
            _id: user.idUser
          }
            this.dependencies.forEach((dep) => {
              if (user.idUser === dep.inChargeAvatar) {
                dep.inChargeAvatar = user.image;
                dep.inChargeName = `${user.names} ${user?.surnames} ${user?.secondSurname}`
              }
            })
          
        });
      });
    } else {
      this.card = null; // O algún valor por defecto
    }
  }
  }

  ngAfterViewInit() {
    if (this.dependenciesDataSource && this.sort) {
      this.dependenciesDataSource.sort = this.sort;
    }
  }

  checkOrder(order: 'asc' | 'desc' | '' = '') {
    if (this.sort.direction !== order) {
      this.sort.sort(<MatSortable>{ id: 'description', start: order, disableClear: true })
    }
  }

  applyFilter(status: 'To Do' | 'WIP' | 'Done') {
    const nextFilter = this.currentFilter === status ? '' : status;
    this.currentFilter = nextFilter;
    this.dependenciesDataSource.filter = nextFilter;
  }

  public changeCard(val: 1 | -1) {
    const indexCard = val + this.currentCard;
    const card = this.dependencies[indexCard];
    if (card) { 
      this.currentCard = indexCard;
      this.card = card;
    }
  }
}

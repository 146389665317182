import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsComponent } from './projects.component';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProjectComponent } from './project/project.component';
import { OverviewComponent } from './project/overview/overview.component';
import { ProgressComponent } from './project/progress/progress.component';
import { AboutComponent } from './project/about/about.component';
import { DependenciesComponent } from './project/dependencies/dependencies.component';
import { WorkPlanComponent } from './project/work-plan/work-plan.component';
import { RisksComponent } from './project/risks/risks.component';
import { AdditionalTasksComponent } from './project/additional-tasks/additional-tasks.component';
import { CommentsComponent } from './project/comments/comments.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import localeMx from '@angular/common/locales/es-MX';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NgxEmojModule } from 'ngx-emoj';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
registerLocaleData(localeMx);


@NgModule({
  declarations: [
    ProjectsComponent,
    ProjectComponent,
    OverviewComponent,
    ProgressComponent,
    AboutComponent,
    DependenciesComponent,
    WorkPlanComponent,
    RisksComponent,
    AdditionalTasksComponent,
    CommentsComponent,
    CustomDatePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    ProjectsRoutingModule,
    NgxEmojModule,
    // Meterial
    MatFormFieldModule,
    PipesModule,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' } 
  ],
  exports: [
    CommentsComponent,
    WorkPlanComponent
  ]
})
export class ProjectsModule { }

<div class="border-table d-none d-md-block">
    <table mat-table [dataSource]="additionalTasks"
        class="deep-table">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>
                Actividades adicionales
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.title}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                Estatus
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.status}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<!-- Mobile view -->
<div class="cards d-md-none">
    <div class="card" *ngIf="card">
        <p class="card__label card__label--primary">Actividad</p>
        <p class="card__label mt-2">{{ card.title }}</p>

        <p class="card__label card__label--primary mt-4">Estatus</p>
        <p class="card__label mt-2">{{ card.status }}</p>
    </div>
    <div class="controls d-flex justify-content-center mt-3">
        <button mat-icon-button type="button" (click)="changeCard(-1)"
            class="p-0 mr-3"
            [disabled]="currentCard <= 0">
            <span class="fs-32 time-icon-left"></span>
        </button>
        <button mat-icon-button type="button" (click)="changeCard(1)"
            class="p-0 ml-3"
            [disabled]="currentCard >= (additionalTasks?.length - 1)">
            <span class="fs-32 time-icon-right"></span>
        </button>
    </div>
</div>

<div class="container-full">
    <div class="container w-100">
        <div class="row">
            <div class="col-12 d-md-none">
                <a [routerLink]="'/hours-teams'" class="text-decoration-none">
                    <span class="time-icon-back fs-16"></span>
                </a>
            </div>
            <div class="col-12 d-none d-md-block mb-4">
                <app-breadcrumb [crumbs]="breadcrumb"> </app-breadcrumb>
            </div>
        </div>
        <div class="row w-100 d-flex flex-column">
            <p class="title">
                Gestión de oportunidades
            </p>
            <div class="col-xs-12 m-auto">
                <div class="content-options">
                    <div class="opt-menu" [ngClass]="{ 'active': activeFlux === 'Propuesta'}"
                        (click)="changeActive('Propuesta')">
                        Propuestas
                    </div>
                    <div class="opt-menu" [ngClass]="{ 'active': activeFlux === 'Proyecto'}"
                        (click)="changeActive('Proyecto')">
                        Proyectos
                    </div>
                    <div class="opt-menu" [ngClass]="{ 'active': activeFlux === 'Asignación'}"
                        (click)="changeActive('Asignación')">
                        Asignaciones
                    </div>
                </div>
            </div>
            <div class="col-12 px-0 d-flex justify-content-between" style="margin-top: 48px;">
                <div class="d-flex">
                    <div class="dropdown">
                        <button class="menu-trigger" mat-button [matMenuTriggerFor]="orderMenu"
                            #orderMenuTrigger="matMenuTrigger">
                            Ordenar
                            <span class="align-middle"
                                [ngClass]="orderMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
                        </button>
                        <mat-menu #orderMenu="matMenu">
                            <button class mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                                Ordenar por
                            </button>
                            <button mat-menu-item (click)="checkOrder('asc')" class="menu-filter-item">
                                <mat-checkbox [checked]="sortDirection === 'asc'"
                                    disabled="true">Ascendente</mat-checkbox>
                            </button>
                            <button mat-menu-item (click)="checkOrder('desc')" class="menu-filter-item">
                                <mat-checkbox [checked]="sortDirection === 'desc'"
                                    disabled="true">Desendente</mat-checkbox>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="dropdown">
                        <button mat-button class="menu-trigger" [matMenuTriggerFor]="filterMenu"
                            #filterMenuTrigger="matMenuTrigger">
                            Filtrar
                            <span class="align-middle"
                                [ngClass]="filterMenuTrigger.menuOpen ? 'time-icon-filter_collapse' : 'time-icon-filter_expand'"></span>
                        </button>
                        <mat-menu #filterMenu="matMenu">
                            <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                                Filtrar por
                            </button>
                            <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                                Cliente
                            </button>
                            <button mat-menu-item *ngFor="let client of filtersCliente" class="menu-filter-item">
                                <mat-checkbox [checked]="client === selectedClient" (click)="onClientFilter(client)">
                                    {{client}}
                                </mat-checkbox>
                            </button>
                            <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                                Responsable
                            </button>
                            <button mat-menu-item *ngFor="let responsable of filtersResponsable"
                                class="menu-filter-item">
                                <mat-checkbox [checked]="responsable.id === selectedResponsable?._id"
                                    (click)="onResponsableFilter(responsable)">
                                    {{responsable.names}}
                                </mat-checkbox>
                            </button>
                            <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                                Área / Equipo
                            </button>
                            <button mat-menu-item *ngFor="let team of filtersArea" class="menu-filter-item">
                                <mat-checkbox [checked]="team === selectedAccessTeam"
                                    (click)="onAccessTeamFilter(team)">
                                    {{team}}
                                </mat-checkbox>
                            </button>
                            <button mat-menu-item class="menu-filter-item mat-item-label" disabled="true">
                                Estatus
                            </button>
                            <button mat-menu-item *ngFor="let status of filtersStatus" class="menu-filter-item">
                                <mat-checkbox [checked]="status === selectedStatus" (click)="onStatusFilter(status)">
                                    {{status}}
                                </mat-checkbox>
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="button-opportunity" [routerLink]="'/new-oportunity'" (click)="updateActiveEdit(null)"
                    *ngIf="activeFlux === 'Propuesta'">
                    Crear oportunidad
                </div>
            </div>
            <div class="container px-0 content-table " style="margin-bottom: 50px;">
                <div class="row-content row w-100 mr-0 ml-0">
                    <div class="col-2 justify-content-start align-items-center d-flex title-table ">
                        {{activeFlux}}
                    </div>
                    <div class="col-2 justify-content-start align-items-center d-flex title-table">
                        Cliente
                    </div>
                    <div class="col-2 justify-content-start align-items-center d-flex title-table">
                        Código
                    </div>
                    <div class="col-2 justify-content-center align-items-center d-flex title-table">
                        {{nameleader}}
                    </div>
                    <div class="col-2 justify-content-start align-items-center d-flex title-table">
                        Área / Equipo
                    </div>
                    <div class="col-1 d-flex justify-content-start align-items-center title-table">
                        Estatus
                    </div>
                    <div class="col-1 d-flex justify-content-center align-items-center title-table"></div>
                </div>
                <div class="row-content row w-100 mr-0 ml-0" *ngIf="projectsTemplate.length === 0">
                    <div class="col-12 text-center">
                        No hay datos para mostrar
                    </div>
                </div>
                <div class="row-content row w-100 mr-0 ml-0" *ngFor="let oportunity of projectsTemplate; index as i">

                    <div class="col-2 justify-content-start align-items-center d-flex body-item ">
                        {{oportunity.name}}
                    </div>
                    <div class="col-2 justify-content-start align-items-center d-flex body-item">
                        {{oportunity.client}}
                    </div>
                    <div class="col-2 justify-content-start align-items-center d-flex body-item">
                        {{oportunity.code}}
                    </div>
                    <div class="col-2 justify-content-center align-items-center d-flex body-item">
                        <img matTooltipClass="custom-tooltip" [matTooltip]="oportunity?.name2bcore"
                            matTooltipPosition="right"
                            [src]="oportunity?.imageResponsalbe ? (oportunity?.imageResponsalbe | imagePath: 'profilePicture') :'assets/img/person.svg'"
                            class="image" alt="">
                    </div>
                    <div class="col-2 justify-content-start align-items-center d-flex body-item">
                        <div class="team-rect" matTooltipClass="custom-tooltip"
                            [matTooltip]="oportunity?.accessTeam?.[0]?.length > 8 ? oportunity.accessTeam[0] : ''"
                            matTooltipPosition="right"> {{oportunity.accessTeam[0]}}</div>
                        <div class="circle-areas" *ngIf="oportunity.accessTeam.length > 1">
                            +{{oportunity.accessTeam.length-1}}</div>
                    </div>
                    <div class="col-1 d-flex justify-content-start align-items-center body-item">
                        {{oportunity.statusOportunity}}
                    </div>
                    <div class="col-1 d-flex justify-content-start align-items-center ">
                        <div class="btn-group">
                            <button class="" style="border: none;background-color: transparent;" type="button"
                                data-toggle="dropdown" aria-expanded="false">
                                <span class="time-icon-options"></span>
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" [routerLink]="'/new-oportunity'"
                                    (click)="updateActiveEdit(oportunity._id)"><span
                                        class="time-icon-option_edit mr-2"></span>Editar</a>
                                <a class="dropdown-item" style=" color: #e4254b; cursor: pointer;"
                                    (click)="deleteOportunity(oportunity)"><span
                                        class="time-icon-option_delete mr-2"></span>Eliminar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
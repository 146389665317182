<div class="container w-90">
    <div class="row mb-4">
        <div class="col">
            <div class="d-flex align-items-center cr-pointer" [routerLink]="['/hours-teams/report', employeeId]">
                <span class="time-icon-back mr-2"></span>
                <span class="back">Regresar</span>
            </div>
        </div>
    </div>
    <div class="row justify-content-between align-items-end px-3 my-5">
        <div>
            <p class="title mb-0">Detalles de la actividad</p>
        </div>

        <div>
            <span class="d-flex align-items-center">
                <span class="time-icon-calendar_active mr-2"></span>
                <span class="day-title">{{ today | date: dateFormat }}</span>
            </span>            
        </div>
    </div>

    <div class="row" *ngIf="activityDetail">
        <div class="col">
            <div class="activity-details">
                <div class="chart-box">
                    <div class="detail-item">
                        <div class="label">Estatus:</div>
                        <div class="value d-flex align-items-center">
                            <span class="mr-2" *ngIf="activityDetail.status === 'approved'">
                                <span class="time-icon-status_approved"><span class="path1"></span><span
                                        class="path2"></span></span>
                            </span>
                            <span class="mr-2" *ngIf="activityDetail.status === 'pending'">
                                <span class="time-icon-status_pending"><span class="path1"></span><span
                                        class="path2"></span></span>
                            </span>
                            <span class="mr-2" *ngIf="activityDetail.status === 'rejected'">
                                <span class="time-icon-status_rejected"><span class="path1"></span><span
                                        class="path2"></span></span>
                            </span>
                            {{activityDetail.status | titlecase}}
                        </div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Colaborador:</div>
                        <div class="value">{{employeeName}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Actividad:</div>
                        <div class="value">{{activityDetail.activity}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Tipo de actividad:</div>
                        <div class="value">{{activityDetail.type}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Código:</div>
                        <div class="value">{{activityDetail.code}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Tiempo</div>
                        <div class="value">{{activityDetail.hours}} h : {{activityDetail.minutes}} min</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Descripción de la actividad:</div>
                        <div class="value">{{activityDetail.description}}</div>
                    </div>
                </div>

                <div class="d-flex justify-content-md-end justify-content-center mt-5 mb-4">
                    <button class="btn button-decline mr-3" (click)="openModal('decline')">
                        <span class="time-icon-button_approve mr-2"></span>
                        Rechazar
                    </button>
                    <button class="btn button-accept" (click)="openModal('success')">
                        <span class="time-icon-button_decline mr-2"></span>
                        Aprobar
                    </button>
                </div>

                <div class="mb-5">
                    <p class="day-title">Comentarios</p>
                    <form action="">
                        <div class="d-md-flex align-items-center">
                            <img class="avatar-40 mr-2" src="../../../../../assets/users/female-avatar.svg" alt="">
                            <span class="input-style w-100 mr-md-2">
                                <mat-form-field class="w-100" appearance="outline" subscriptSizing="dynamic">
                                    <mat-label>Añadir comentario</mat-label>
                                    <input matInput formControlName="" type="text" id="" required>
                                </mat-form-field>
                                <span class="time-icon-comment_emoji icon-position"></span>
                            </span>
                            <button class="btn btn-primary" (click)="onSubmit()">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showSuccessModal" class="custom-modal-overlay">
    <div class="custom-modal">
        <div class="custom-modal-header">
            <button (click)="closeModal('success')">
                <span class="material-icons">close</span>
            </button>
        </div>

        <div class="custom-modal-body">
            <div class="mt-4 mb-3">
                <span class="time-icon-status_approved" style="font-size: 40px;"><span class="path1"></span><span
                        class="path2"></span></span>
            </div>
            <p class="modal-title mb-3">Actividad aprobada</p>
            <p class="modal-text">Has aprobado esta actividad con éxito.</p>
        </div>

        <div class="custom-modal-footer px-3 mb-3">
            <button type="submit" class="btn btn-primary w-100" (click)="closeModal('success')">Aceptar</button>
        </div>
    </div>
</div>

<div *ngIf="showDeclineModal" class="custom-modal-overlay">
    <div class="custom-modal">
        <div class="custom-modal-header">
            <button (click)="closeModal('decline')">
                <span class="material-icons">close</span>
            </button>
        </div>

        <div class="custom-modal-body px-3">
            <div class="mt-4 mb-3">
                <span class="time-icon-status_rejected" style="font-size: 40px;"><span class="path1"></span><span
                        class="path2"></span></span>
            </div>
            <p class="modal-title mb-4">Rechazar actividad</p>
            <p class="modal-text line-break mb-4">
                Para rechazar esta actividad, indícale a
                Lucero Jiménez qué es lo que debe corregir.
            </p>
            <mat-form-field class="w-100" appearance="outline" subscriptSizing="dynamic">
                <mat-label>Ingresa tu comentario</mat-label>
                <textarea matInput name="description" id="textarea" rows="5" maxlength="112" required></textarea>
            </mat-form-field>
        </div>

        <div class="custom-modal-footer px-3 mb-3">
            <button type="submit" class="btn btn-primary w-100" (click)="onSubmit()">Comentar</button>
        </div>
    </div>
</div>

<div *ngIf="showSendModal" class="custom-modal-overlay">
    <div class="custom-modal">
        <div class="custom-modal-header">
            <button (click)="closeModal('send')">
                <span class="material-icons">close</span>
            </button>
        </div>

        <div class="custom-modal-body">
            <div class="mt-4 mb-3">
                <span class="time-icon-status_send" style="font-size:40px;"><span class="path1"></span><span
                        class="path2"></span><span class="path3"></span></span>
            </div>
            <p class="modal-title mb-3">Comentarios enviados</p>
            <p class="modal-text line-break">
                Has enviado un comentario a Lucero Jiménez
                sobre esta actividad. En breve, le informaremos.
            </p>
        </div>

        <div class="custom-modal-footer px-3 mb-3">
            <button type="submit" class="btn btn-primary w-100" (click)="closeModal('send')">Cerrar</button>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 d-md-none">
                    <a [routerLink]="'/hours-teams'" class="text-decoration-none">
                        <span class="time-icon-back fs-16"></span>
                    </a>
                </div>
                <div class="col-12 d-none d-md-block mb-4">
                    <app-breadcrumb [crumbs]="breadcrumb"> </app-breadcrumb>
                </div>
            </div>
            <div class="title" *ngIf="comercialForm.get('category').value === 'Propuesta comercial' && isNew">
                Crear propuesta comercial (oportunidad)
            </div>
            <div class="title" *ngIf="comercialForm.get('category').value === 'Propuesta comercial' && !isNew">
               {{comercialForm.get('category').value}}: {{comercialForm.get('name').value}}
            </div>
            <div class="subtitle col-12" *ngIf="comercialForm.get('category').value !== 'Propuesta comercial'">
                {{comercialForm.get('category').value}} : <strong>{{comercialForm.get('name').value}}</strong>
            </div>
        </div>
        <div class="col-10 m-auto">
            <form [formGroup]="comercialForm">
                <div class="row px-0">
                    <div class="subtitle col-12" >
                        Acerca de {{comercialForm.get('category').value !== 'Propuesta comercial' ? '' : ''}}   oportunidad
                    </div>
                   
                    <div class="col-6 content-input form-group" *ngIf="!isNew">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field" 
                            [ngClass]="comercialForm.get('statusOportunity').value  !== 'Aceptada' ? 'disabled' : ''">
                            <mat-label [ngClass]="comercialForm.get('statusOportunity').value  !== 'Aceptada' ? 'disabled' : ''">Selecciona una categoría *</mat-label>
                            <mat-select formControlName="category" (selectionChange)="onCategoryChange()">
                                <mat-option value="Propuesta comercial">
                                    Propuesta comercial
                                </mat-option>
                                <mat-option value="Asignación">
                                    Asignación
                                </mat-option>
                                <mat-option value="Proyecto">
                                    Proyecto
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"
                            [ngClass]="comercialForm.get('statusOportunity').value  !== 'Aceptada' ? 'disabled' : ''"></span>
                    </div>
                    <div class=" content-input form-group" [ngClass]="!isNew ? 'col-6' : 'col-12'">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Nombre de la oportunidad *</mat-label>
                            <input matInput value="" formControlName="name"   [textMask]="{mask: maskAlphaNum, guide: false}">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('name')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa un nombre
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Código *</mat-label>
                            <input matInput value="" formControlName="code"  [textMask]="{mask: maskCode, guide: false}"    (input)="convertToUpper($event)">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('code')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa un código
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Cliente *</mat-label>
                            <input matInput value="" formControlName="client" [textMask]="{mask: maskAlphaNum, guide: false}">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('client')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa un cliente
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Responsable (Cliente) *</mat-label>
                            <input matInput value="" formControlName="responsableClient" [textMask]="{mask: maskAlpha, guide: false}">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('responsableClient')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa un responsable (cliente)
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Responsable 2BCORE *</mat-label>
                            <img  [hidden]="!selectedOption" [src]="selectedOption?.image ? (selectedOption?.image | imagePath: 'profilePicture') :'assets/img/person.svg'" class="custom-input-image" alt="Imagen seleccionada">

                            <input type="text" matInput [matAutocomplete]="auto"  formControlName="responsable2bcore"
                                [ngStyle]="{ 'background-size': '30px 30px', 'background-position': '5px center', 'background-repeat': 'no-repeat', 'padding-left': selectedOption ? '40px' : '10px'}"
                               [displayWith]="displayFn" >

                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption  
                                (optionSelected)="onOptionSelected($event.option.value)">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'" alt="{{option.name}}" width="30" height="30"
                                        style="margin-right: 10px;">
                                    {{ option.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('responsable2bcore')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tiempo *</mat-label>
                            <mat-select formControlName="time">
                                <mat-option *ngFor="let activity of catActivities" [value]="activity.name">
                                    {{activity.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('time')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Viabilidad de cierre *</mat-label>
                            <mat-select formControlName="viabilityEnd">
                                <mat-option value="1">
                                    1 (Alta)
                                </mat-option>
                                <mat-option value="2">
                                    2 (Media)
                                </mat-option>
                                <mat-option value="3">
                                    3 (Baja)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('viabilityEnd')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Último contacto *</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="lastContact">
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('lastContact')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field  appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field"  [ngClass]="(comercialForm.get('statusOportunity').value  === 'Aceptada') ? 'disabled' : ''">
                            <mat-label>Estatus de la oportunidad *</mat-label>
                            <mat-select formControlName="statusOportunity" (selectionChange)="validateStatus($event)">
                                <mat-option value="Discovery">
                                    Discovery
                                </mat-option>
                                <mat-option value="Enviada" *ngIf="!isNew">
                                    Enviada
                                </mat-option>
                                <mat-option value="Aceptada" *ngIf="!isNew">
                                    Aceptada
                                </mat-option>
                                <mat-option value="Desarrollo" *ngIf="!isNew">
                                    Desarrollo
                                </mat-option>
                                <mat-option value="Standby" *ngIf="!isNew">
                                    Standby
                                </mat-option>
                                <mat-option value="Cerrada" *ngIf="!isNew">
                                    Cerrada
                                </mat-option>
                            </mat-select>
                            <!-- <span class="time-icon-info fs-16" *ngIf="comercialForm.get('statusOportunity').value  === 'Aceptada'"  matTooltip="El proyecto fue aceptado por el cliente, por lo que no puedes modificar el estatus."  matTooltipPosition="right"><span class="path1"></span><span class="path2"></span></span> -->
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16" [ngClass]="comercialForm.get('statusOportunity').value  === 'Aceptada' ? 'disabled' : ''"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('statusOportunity')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>

                    <div class="col-12 content-input"
                        *ngIf="comercialForm.get('statusOportunity').value  === 'Cerrada'">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Describe por qué el cliente no aprobó la propuesta *
                            </mat-label>
                            <input matInput value="" formControlName="commentDecline">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('commentDecline')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa la descripción
                            </span>
                        </div>
                    </div>
                    <div class="col-12 content-input" *ngIf="comercialForm.get('statusOportunity').value  === 'Standby'">
                        <p style=" color: #01173c;font-size: 18px;"><span class="time-icon-info fs-16 mr-2" matTooltipPosition="right"><span class="path1"></span><span class="path2"></span></span>Para reactivar esta propuesta, es necesario
                            establecer la fecha de reactivación:</p>
                    </div>
                    <div class="col-6 content-input" *ngIf="comercialForm.get('statusOportunity').value  === 'Standby'">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de reactivación *</mat-label>
                            <input (change)="comercialForm.get('statusOportunity').updateValueAndValidity()" matInput [matDatepicker]="picker8" formControlName="reactivationDay">
                            <mat-datepicker-toggle matIconSuffix [for]="picker8">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker8></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('reactivationDay')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row px-0" *ngIf="comercialForm.get('category').value  === 'Asignación'">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Información sobre de la asignación
                    </div>
                    <div class="col-12">
                        <p class="fs-18" style="color: #01173c;">Al crear una nueva asignación, es necesario completar la siguiente información.</p>
                    </div>
                    <div class="col-6 content-input form-group"> 
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de inicio *</mat-label>
                            <input matInput [matDatepicker]="pickerAssign1" formControlName="dateInitAsign">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerAssign1">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerAssign1></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateInitAsign')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de fin *</mat-label>
                            <input matInput [matDatepicker]="pickerAssign2" formControlName="dateEndAsign">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerAssign2">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerAssign2></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateEndAsign')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Líder técnico *</mat-label>
                            <input matInput value="" formControlName="technicalLead">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('technicalLead')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa un líder técnico
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Horas reales *</mat-label>
                            <input matInput value="" formControlName="realHour">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('realHour')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa las horas reales
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row px-0" *ngIf="comercialForm.get('category').value  === 'Proyecto'">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Información sobre el proyecto
                    </div>
                    <div class="col-12">
                        <p class="fs-18" style="color: #01173c;">Al crear un nuevo proyecto, es necesario completar la siguiente información.</p>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de inicio *</mat-label>
                            <input matInput [matDatepicker]="pickerProyect1" formControlName="dateInitProyect">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerProyect1">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerProyect1></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateInitProyect')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de fin *</mat-label>
                            <input matInput [matDatepicker]="pickerProyect2" formControlName="dateEndProyect">
                            <mat-datepicker-toggle matIconSuffix [for]="pickerProyect2">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerProyect2></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateEndProyect')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tipo de proyecto *</mat-label>
                            <mat-select formControlName="typeProyect">
                                <mat-option value="Ejecución Única">
                                    Ejecución Única
                                </mat-option>
                                <mat-option value="Tiempo – Materiales">
                                    Tiempo – Materiales
                                </mat-option>
                                <mat-option value="Soporte y Mantenimiento">
                                    Soporte y Mantenimiento
                                </mat-option>
                                <mat-option value="Objetivo y Precio fijo">
                                    Objetivo y Precio fijo
                                </mat-option>
                                <mat-option value="Capacitación">
                                    Capacitación
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('typeProyect')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Metodología *</mat-label>
                            <mat-select formControlName="metodology">
                                <mat-option value="Agile">
                                    Agile
                                </mat-option>
                                <mat-option value="Waterfall">
                                    Waterfall
                                </mat-option>
                                <mat-option value="Hibrida">
                                    Hibrida
                                </mat-option>
                            
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('metodology')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tecnología *</mat-label>
                            <input matInput value="" formControlName="technology">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('technology')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa la tecnología utilizada
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tiempo de garantía (Días / Horas)</mat-label>
                            <input matInput value="" formControlName="timeGaranty">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('timeGaranty')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa el tiempo de garantía
                            </span>
                        </div>
                    </div>
                    <div class="col-12 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Líder de proyecto *</mat-label>
                            <img  [hidden]="!selectedOptionProyLead" [src]="selectedOptionProyLead?.image ? (selectedOptionProyLead?.image | imagePath: 'profilePicture') :'assets/img/person.svg'" class="custom-input-image" alt="Imagen seleccionada">

                            <input type="text" matInput [matAutocomplete]="auto"  formControlName="proyectLead"
                                [ngStyle]="{ 'background-size': '30px 30px', 'background-position': '5px center', 'background-repeat': 'no-repeat', 'padding-left': selectedOption ? '40px' : '10px'}"
                               [displayWith]="displayFn" >

                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption  
                                (optionSelected)="onOptionSelectedProyLead($event.option.value)">
                                <mat-option *ngFor="let option of filteredOptionsProyLead | async" [value]="option">
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'" alt="{{option.name}}" width="30" height="30"
                                        style="margin-right: 10px;">
                                    {{ option.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('proyectLead')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa un líder de proyecto
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row px-0" *ngIf="comercialForm.get('statusOportunity').value  === 'Aceptada'">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Información de pago
                    </div>
                    <div class="col-12">
                        <p class="fs-18 mb-4" style="color: #01173c;">Al actualizar el estatus de la oportunidad a
                            'Aceptada’, es necesario completar
                            la siguiente información.</p>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de aprobación *</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="dateApprove">
                            <mat-datepicker-toggle matIconSuffix [for]="picker2">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateApprove')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de orden de compra *</mat-label>
                            <input matInput [matDatepicker]="picker3" formControlName="dateOrderBuy">
                            <mat-datepicker-toggle matIconSuffix [for]="picker3">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateOrderBuy')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tipo de moneda *</mat-label>
                            <mat-select formControlName="currency">
                                <mat-option value="Peso mexicano">
                                    Peso mexicano (MXN)
                                </mat-option>
                                <mat-option value="  Dólar (USD)">
                                    Dólar estadounidense (USD)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('currency')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Monto cotizado *</mat-label>
                            <input matInput value="" formControlName="amount">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('amount')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa el monto cotizado
                            </span>
                        </div>
                    </div>
                    <div class="col-6 row px-0 m-0">
                        <div class="col-5 content-input form-group">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                                <mat-label>Cotización</mat-label>
                                <mat-select formControlName="price">
                                    <mat-option value="Días">
                                        Días
                                    </mat-option>
                                    <mat-option value="Horas">
                                        Horas
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="time-icon-arrow_expand fs-16"></span>
                            <div class="form-control-error py-1">
                                <span *ngIf="registryHasError('price')" class="d-flex align-items-center">
                                    <span class="time-icon-status_error mr-2">
                                        <span class="path1"></span><span class="path2"></span>
                                    </span>
                                    Selecciona una opción
                                </span>
                            </div>
                        </div>
                        <div class="col-7 content-input form-group">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                                <mat-label>{{comercialForm.get('price').value === 'Horas' ? 'Total horas cotizadas *' :
                                    'Total días cotizados *'}}</mat-label>
                                <input matInput value="" formControlName="totalHoursQuoted">
                            </mat-form-field>
                            <div class="form-control-error py-1">
                                <span *ngIf="registryHasError('totalHoursQuoted')" class="d-flex align-items-center">
                                    <span class="time-icon-status_error mr-2">
                                        <span class="path1"></span><span class="path2"></span>
                                    </span>
                                    Ingresa el {{comercialForm.get('price').value === 'Horas' ? 'total horas cotizadas' :
                                    'total días cotizados'}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tipo de pago *</mat-label>
                            <mat-select formControlName="agreedPayments">
                                <mat-option value="Mensual">
                                    Mensual
                                </mat-option>
                                <mat-option value="Quincenal">
                                    Quincenal
                                </mat-option>
                                <mat-option value="Trimestral">
                                    Trimestral
                                </mat-option>
                                <mat-option value="Trimestral">
                                    Otra (Número de pagos acordados)
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('agreedPayments')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de procesamiento de pago *</mat-label>
                            <input matInput [matDatepicker]="picker4" formControlName="dateProcessPayment">
                            <mat-datepicker-toggle matIconSuffix [for]="picker4">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateProcessPayment')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Tipo de pago *</mat-label>
                            <mat-select formControlName="typePayment">
                                <mat-option value="Diferido 50/50">
                                    Diferido 50/50
                                </mat-option>
                                <mat-option value="Mensual">
                                    Mensual
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('typePayment')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Documento de pago *</mat-label>
                            <mat-select formControlName="paymentDocument">
                                <mat-option value="Contrato">
                                    Contrato
                                </mat-option>
                                <mat-option value="Orden de compra">
                                    Orden de compra
                                </mat-option>
                                <mat-option value="Orden de compra/Contrato">
                                    Orden de compra/Contrato
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('paymentDocument')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Centro de costos *</mat-label>
                            <input matInput value="" formControlName="costCenter">
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('costCenter')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Ingresa el centro de costos
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Frecuencia de facturación *</mat-label>
                            <mat-select formControlName="billingFrequency">
                                <mat-option value="30 días">
                                    30 días
                                </mat-option>
                                <mat-option value="Mensual">
                                    60 Días
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('billingFrequency')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una opción
                            </span>
                        </div>
                    </div>
                    <div class="col-6 content-input form-group">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label>Fecha de inicio de facturación *</mat-label>
                            <input matInput [matDatepicker]="picker5" formControlName="dateInitBilling">
                            <mat-datepicker-toggle matIconSuffix [for]="picker5">
                                <mat-icon class="time-icon-calendar_active" matDatepickerToggleIcon></mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker5></mat-datepicker>
                        </mat-form-field>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('dateInitBilling')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona una fecha
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row px-0">
                    <div class="subtitle col-12" style="margin-bottom: 21px; margin-top: 20px;">
                        Miembros
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field">
                            <mat-label> Área(s) / Equipo(s) con acceso al código *</mat-label>
                            <mat-select formControlName="accessTeam" multiple class="d-none" (selectionChange)="onSelectionChangeMember($event)">
                                <mat-option *ngFor="let team of teamsList" [value]="team.name">
                                    <span>
                                        {{team.name}}
                                    </span>
                                </mat-option>
                            </mat-select>

                            <div class="selected-chips">
                                <mat-chip *ngFor="let option of comercialForm.get('accessTeam').value"
                                    [removable]="true" (removed)="remove(option)">
                                    {{ option }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('accessTeam')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona las área(s) / equipo(s)
                            </span>
                        </div>
                    </div>
                    <div class="col-12">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-100 deep-mat-field" [floatLabel]="shouldLabelFloat ? 'always' : 'auto'">
                            <mat-label> Staff autorizado *</mat-label>
                            <mat-select #staffSelect  formControlName="authorizedStaff" multiple class="d-none" floatLabel="always">
                                <mat-option *ngFor="let option of optionsStaff" [value]="option" 
                                (focus)="onFocus()" 
                                (blur)="onBlur()" 
                                (selectionChange)="onSelectionChange()"
                                >
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'" width="25" height="25"
                                        style="margin-right: 10px;">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>

                            <div class="selected-chips">
                                <mat-chip *ngFor="let option of comercialForm.get('authorizedStaff').value"
                                    [removable]="true" (removed)="removeUserChip(option)">
                                    <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'"  width="25" height="25"
                                        style="margin-right: 10px;"> {{ option.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </div>
                        </mat-form-field>
                        <span class="time-icon-arrow_expand fs-16"></span>
                        <div class="form-control-error py-1">
                            <span *ngIf="registryHasError('authorizedStaff')" class="d-flex align-items-center">
                                <span class="time-icon-status_error mr-2">
                                    <span class="path1"></span><span class="path2"></span>
                                </span>
                                Selecciona el staff autorizado
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end mb-5">
                        <button class="button-custom-cancel mr-2"
                            (click)="cancelNewOportunity()">Cancelar</button>
                        <button class="button-accept" data-toggle="modal"
                            [ngClass]="!comercialForm.valid ? 'disabled' : ''" [disabled]="comercialForm.invalid"
                            (click)="onSubmit()">{{!isNew ? 'Guardar' :
                            'Crear oportunidad'}}</button>
                           
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
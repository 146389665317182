<div class="progress-container">
  <div class="tags">
    <p class="tag mr-4"><span class="color color--blue"></span> <span>Proyección</span></p>
    <p class="tag"><span class="color color--green"></span> <span>Progreso</span></p>
  </div>
  <div class="progress progress--blue mt-32">
    <div class="progress-bar" role="progressbar" [style]="'width: '+ projection +'%;'" [attr.aria-valuenow]="projection"
      [matTooltip]="'Horas proyectadas: ' + projection + 'h'"
      matTooltipPosition="above" aria-tooltip="Displays tooltip with projected progress"
      aria-valuemin="0" aria-valuemax="100">{{ projection }}%</div>
  </div>
  <div class="progress progress--green mt-4">
    <div class="progress-bar" role="progressbar" [style]="'width: '+ realProgress +'%;'"
      [matTooltip]="'Horas reales: ' + realProgress + 'h'"
      matTooltipPosition="above" aria-tooltip="Displays tooltip with real progress"
      [attr.aria-valuenow]="realProgress" aria-valuemin="0" aria-valuemax="100">{{ realProgress }}%</div>
  </div>
</div>
import { LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeManagementRoutingModule } from './time-management-routing.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import {TextFieldModule} from '@angular/cdk/text-field';

import { MaterialExampleModule } from 'src/app/material.module';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { RootModule } from './root/root.module';

import { ComercialComponent } from './comercial/comercial.component';
import { OportunityComponent } from './oportunity/oportunity.component';

import { defineLocale, esLocale } from 'ngx-bootstrap/chronos';
import { deLocale, frLocale, plLocale } from 'ngx-bootstrap/locale';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ComponentsModule } from '@components/components.module';
import { EditProyectsComponent } from './pmo/edit-proyects/edit-proyects.component';
import { ModalOportunityComponent } from './comercial/modal-oportunity/modal-oportunity.component';
import { ProjectsModule } from '@pmo/projects/projects.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { ModalTaskComponent } from './comercial/modal-task/modal-task/modal-task.component';
import { TextMaskModule } from 'angular2-text-mask';


defineLocale('de', deLocale);
defineLocale('fr', frLocale);
defineLocale('pl', plLocale);
defineLocale('es', esLocale);
registerLocaleData(localeEs);



@NgModule({
  declarations: [
    ComercialComponent,
    OportunityComponent,
    EditProyectsComponent,
    ModalOportunityComponent,
    ModalTaskComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ProjectsModule,
    MatChipsModule,
    PipesModule,
    MatIconModule,
    PipesModule,
    MaterialExampleModule,
    MultiSelectModule,
    TextMaskModule,
    MatCheckboxModule,
    MatMenuModule,
    TextFieldModule,
    MatAutocompleteModule,
    TimeManagementRoutingModule,
    BrowserModule,
    ProjectsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    RootModule,
    BsDatepickerModule.forRoot(),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // For collaborator
    ComponentsModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, 
    { provide: LOCALE_ID, useValue: 'es-ES' } 
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class TimeManagementModule { }

<div class="overview">
    <div class="row">
        <div class="col-12 col-md d-flex flex-column-reverse flex-md-row align-items-center ">
            <p class="overview__title text-center text-truncate">
                Proyecto: 
                <br class="d-inline d-md-none">
                <b>{{overview?.title}}</b>
            </p>
            <span class="status-chip ml-0 ml-md-4 mb-3 mb-md-0">{{ overview?.status }}</span>
        </div>
        <div class="col-12 col-md-auto overview__elements justify-content-center justify-content-md-end mt-4 mt-md-0">
            <button class="overview__element" type="button" mat-button matTooltip="Sprint"
                matTooltipPosition="above" aria-label="Button that displays a tooltip when focused or hovered over">
                <span class="time-icon-sprint align-middle mr-1"></span>
                <span class="overview__sprint align-middle">
                    {{ overview?.sprint }}
                </span>
            </button>
            <button class="overview__element" type="button" mat-button matTooltip="Team"
                matTooltipPosition="above" aria-label="Button that displays a tooltip when focused or hovered over">
                <span class="time-icon-menu_team align-middle mr-1"></span>
                <span class="overview__team-count align-middle">
                    {{ overview?.team.length }}
                </span>
            </button>
            <button class="overview__element" type="button" mat-button matTooltip="Created At"
                matTooltipPosition="above" aria-label="Button that displays a tooltip when focused or hovered over">
                <span class="time-icon-calendar_active align-middle mr-1"></span>
                <span class="overview__date align-middle">
                    {{ overview?.updatedAt | date:'shortDate' }}
                </span>
            </button>
        </div>
    </div>
    <div class="row align-items-center flex-column flex-md-row mt-32">
        <div class="col-auto pr-0">
            <img
            [src]="resp2bc?.image? (resp2bc?.image| imagePath: 'profilePicture') :'assets/img/person.svg'"
            class="overview__avatar" />
        </div>
        <div class="col-auto col-md mt-3 mt-md-0 text-center text-md-left">
            <p class="overview__leader-name">{{ resp2bc?.name}}</p>
            <p class="overview__leader-label mt-2">Lider de proyecto</p>
        </div>
        <div class="col-auto overview__avatar-collection mt-4 mt-md-0">
            <p class="label-staff d-none d-md-block">
                Miembros del Staff
            </p>
            <app-avatar-collection [allEmployees]="allEmployees" [team]="overview?.team"></app-avatar-collection>
            <p class="label-staff d-block d-md-none mt-3">
                Miembros del Staff
            </p>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Project, proposals } from './projects.const';
import { Employee, employees, leaders } from '@pmo/teams/team/team.const'
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { EmployeesService } from 'src/app/services';
import { switchMap, tap } from 'rxjs/operators';

export interface ProjectTemplate extends Project {
  client: string
  code: string
  name: string
  statusOportunity: string
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  //bandera para flujo activo por defecto

  activeFlux = 'propuesta';
  tmRole: any;
  public employees = employees.slice();
  public projectsTemplate: ProjectTemplate[] = [];
  private auxProjectsTemplate: ProjectTemplate[] = [];
  public sortDirection: 'asc' | 'desc' | '' = '';
  public currentFilter: string = '';
  public leadersName: string[] = [];
  public readonly elementsByPage = 6;
  private page = 0;
  allProjects;
  public breadcrumb: Breadcrumb[] = [
    CRUMBS.pmoProjects,
  ];

  constructor(private ProjectService: OportunityService,
    private oportunityService: OportunityService,
    private employeeService: EmployeesService
  ) {

  }


  ngOnInit(): void {
    this.tmRole = sessionStorage.getItem('timeManagementRole');
    this.tmRole === 'proyectLeader' ? this.breadcrumb = [
      CRUMBS.proyectLeader
    ] : '';
    this.activeFlux = this.tmRole === 'proyectLeader' ? 'proyecto' : 'propuesta';
    const idCompany = sessionStorage.getItem('idCompanySelected')
    this.ProjectService.getOportunitiesByCategory().pipe(
      tap(res => {
        this.allProjects = res[0];
        console.log("🚀 ~ ProjectsComponent ~ this.ProjectService.getOportunitiesByCategory ~ this.allProjects:", this.allProjects);
        this.resetProjectsTemplate();
        this.buildFilter();
      }),
      switchMap(() => this.employeeService.getAllCompanyEmployees(idCompany))
    ).subscribe(
      (data: any) => {
        console.log("🚀 ~ ProjectsComponent ~ getAllCompanyEmployees ~ data:", data);
        this.allProjects?.asignaciones.forEach((element: any) => {
          let user = data.filter(el => el._id === element.responsable2bcore);
          element.image = user ? user[0].image : '';
        });
        this.allProjects?.propuestas.forEach((element: any) => {
          let user = data.filter(el => el._id === element.responsable2bcore);
          element.image = user ? user[0].image : '';
        });
        this.allProjects?.proyecto.forEach((element: any) => {
          let user = data.filter(el => el._id === element.responsable2bcore);
          element.image = user ? user[0].image : '';
        });
        console.log(this.allProjects?.proyecto);

      },
      (error) => {
      }
    );

    // this.buildProjects([...proposals, ...proposals, ...proposals]);
  }


  private buildFilter() {
    this.leadersName = [];
    this.projectsTemplate.forEach((project) => {
      if (!this.leadersName.includes(project.client)) {
        this.leadersName.push(project.client);
      }
    })
  }

  changeActive(flux: any) {
    this.activeFlux = flux;
    this.resetProjectsTemplate()
    this.buildFilter();
  }

  checkOrder(order: 'asc' | 'desc' | '' = '') {
    if (order === this.sortDirection) order = '';
    this.sortDirection = order;

    if (order === '') {
      this.resetProjectsTemplate()
      return;
    }
    if (order === 'asc') {
      this.projectsTemplate.sort((projectA, projectB) => projectA.name.localeCompare(projectB.name));
      return;
    }
    if (order === 'desc') {
      this.projectsTemplate.sort((projectA, projectB) => projectB.name.localeCompare(projectA.name));
      return;
    }
  }

  applyFilter(leaderName: string) {
    const nextFilter = this.currentFilter === leaderName ? '' : leaderName;
    this.currentFilter = nextFilter;

    if (nextFilter === '') {
      this.resetProjectsTemplate()
      return;
    }
    this.projectsTemplate = this.auxProjectsTemplate.filter((team) => team.client.includes(nextFilter)).slice();
  }

  private resetProjectsTemplate() {
    switch (this.activeFlux) {
      case 'proyecto':
        this.projectsTemplate = this.allProjects.proyecto.slice();
        this.auxProjectsTemplate = this.allProjects.proyecto.slice();
        break;
      case 'asignacion':
        this.projectsTemplate = this.allProjects.asignaciones.slice();
        this.auxProjectsTemplate = this.allProjects.asignaciones.slice();
        break;
      default:
        this.projectsTemplate = this.allProjects.propuestas.slice();
        this.auxProjectsTemplate = this.allProjects.propuestas.slice();
        break
    }
  }

  public setPage(page: number) {
    this.page = page;
  }

  dataToDetail(project: any) {
    this.oportunityService.activeProject.next(project);
    localStorage.setItem('activeProyectStorage', JSON.stringify(project))
  }

  public getElementToShow(): ProjectTemplate[] {
    const indexStart = this.page * this.elementsByPage;
    const indexEnd = indexStart + this.elementsByPage;
    return this.tmRole === 'proyectLeader' ? this.projectsTemplate.slice(indexStart, indexEnd).filter((oportunity) => oportunity.category = 'Proyecto') : this.projectsTemplate.slice(indexStart, indexEnd);
  }

}

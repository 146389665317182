import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { log } from 'console';
import { switchMap } from 'rxjs/operators';
import { EmployeesService } from 'src/app/services';
import { AccessService } from 'src/app/services/access/access.service';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';

@Component({
  selector: 'app-comercial',
  templateUrl: './comercial.component.html',
  styleUrls: ['./comercial.component.scss']
})
export class ComercialComponent {
  usersByCompany: any;

  localOportunities: [any];
  sortDirection: 'asc' | 'desc' | '' = '';
  projectsTemplate: any = [];
  auxProjectsTemplate: any = [];
  nameleader: any = 'Responsable';
  filtersCliente = [];
  filtersResponsable = [];
  filtersArea = [];
  filtersStatus = [];
  selectedClient: string | null = null;
  selectedAccessTeam: string | null = null;
  selectedResponsable: any;
  selectedStatus: string | null = null;
  public readonly breadcrumb: Breadcrumb[] = [ CRUMBS.oportunityManage ];

  currentFilter: string = '';
  responsableName: any;
  updatedOportunities: [any];
  //bandera para flujo activo por defecto
  activeFlux = 'Propuesta';
  constructor(
    private accessService: AccessService,
    private oportunitiesService: OportunityService,
    public dialog: MatDialog,
    private employeeService: EmployeesService
  ) {
    this.changeActive(this.activeFlux);
    const idCompany = sessionStorage.getItem('idCompanySelected')
    this.employeeService.getAllCompanyEmployees(idCompany).pipe(
      switchMap((data: any) => {
        // Procesar el resultado del primer servicio
        this.usersByCompany = data;
        this.usersByCompany.forEach((user: any) => {
          const addUser = {
            name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
            image: user.image,
            _id: user._id
          };
        });
        // Retornar el observable del segundo servicio
        return this.oportunitiesService.getOportunities();
      })
    ).subscribe((data: any) => {
      this.localOportunities = data;
      this.auxProjectsTemplate = this.localOportunities;
      this.projectsTemplate = this.auxProjectsTemplate;

      if (this.projectsTemplate !== null) {
        this.auxProjectsTemplate?.forEach(oportunidad => {
          const usuarioEncontrado = this.usersByCompany?.find(usuario => usuario._id === oportunidad.responsable2bcore);          
          if (usuarioEncontrado) {
            oportunidad.imageResponsalbe = usuarioEncontrado.image;
            oportunidad.name2bcore = `${usuarioEncontrado?.names} ${usuarioEncontrado?.surnames} ${usuarioEncontrado?.secondSurname}`;
          }
          if (!this.responsableName?.includes(oportunidad.names)) {
            this.responsableName?.push(oportunidad.names);
          }
        });        
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Propuesta comercial');        
        this.generateFilters();

      }
    });
  }

  generateFilters(): void {
    // Validación de existencia de datos
    if (!this.projectsTemplate || !Array.isArray(this.projectsTemplate)) {
      console.error("projectsTemplate no es válido o no está definido.");
      return;
    }
    if (!this.usersByCompany || !Array.isArray(this.usersByCompany)) {
      console.error("usersByCompany no es válido o no está definido.");
      return;
    }

    // Filtros únicos
    this.filtersCliente = Array.from(new Set(this.projectsTemplate.map(project => project.client)));

    this.filtersArea = Array.from(
      new Set(this.projectsTemplate.flatMap(project => project.accessTeam))
    );

    // Filtrar responsables basados en IDs que coincidan
    const auxrespFilter = Array.from(new Set(this.projectsTemplate.map(project => project.responsable2bcore)));
    const matchingItems = this.usersByCompany.filter(user =>
      auxrespFilter.includes(user._id)
    );
    this.filtersResponsable = matchingItems || [];
    this.filtersStatus = Array.from(new Set(this.projectsTemplate.map(project => project.statusOportunity)));
  }


  applyFilters(): void {
    if (
      !this.selectedClient &&
      !this.selectedAccessTeam &&
      !this.selectedResponsable &&
      !this.selectedStatus
    ) {
      this.projectsTemplate = [...this.projectsTemplate];
      this.changeActive(this.activeFlux);
      return;
    }

    this.projectsTemplate = this.projectsTemplate.filter(project => {
      return (
        (!this.selectedClient || project.client === this.selectedClient) &&
        (!this.selectedAccessTeam || project.accessTeam?.includes(this.selectedAccessTeam)) &&
        (!this.selectedResponsable || project.responsable2bcore === this.selectedResponsable?._id) &&
        (!this.selectedStatus || project.statusOportunity === this.selectedStatus)
      );
    });
  }
  onClientFilter(client: string): void {
    this.selectedClient = this.selectedClient === client ? null : client; 
    this.applyFilters();
  }

  onAccessTeamFilter(team: string): void {
    this.selectedAccessTeam = this.selectedAccessTeam === team ? null : team;     
    this.applyFilters();
  }

  onResponsableFilter(responsable: any): void {
    if (this.selectedResponsable && this.selectedResponsable._id === responsable._id) {
      this.selectedResponsable = null;
    } else {
      this.selectedResponsable = responsable;
    }

    this.applyFilters(); // Aplica los filtros actualizados    
  }

  onStatusFilter(status: string): void {
    this.selectedStatus = this.selectedStatus === status ? null : status; 
    this.applyFilters();
  }

  changeActive(flux: any) {

    this.activeFlux = flux;
    switch (flux) {
      case 'Propuesta':
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Propuesta comercial');
        this.nameleader = "Responsable";
        this.generateFilters();
        break;
      case 'Proyecto':
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Proyecto');
        this.nameleader = "Líder de proyecto";
        this.generateFilters();
        break;
      default:
        this.projectsTemplate = this.auxProjectsTemplate.filter(oportunity => oportunity.category === 'Asignación');
        this.nameleader = "Líder técnico";
        this.generateFilters();
    }
  }

  updateActiveEdit(index) {
    this.accessService.opportunityActiveIndex.next(index);
  }
  deleteOportunity(data: any) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_rejected',
        warn: true,
        title: `Eliminar ${data.category.toLowerCase()}`,
        description: 'Si lo eliminas perderás la información. ¿Deseas continuar?',
        close: 'Cancelar',
        confirm: `Eliminar`
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      // TODO: route ver detalles : result === true 
      if (result) {
        const objData = {
          id: data._id
        }
        this.oportunitiesService.deleteOportunity(objData).subscribe((data) => {
          location.reload();
        });
      }
    });

  }
  checkOrder(order: 'asc' | 'desc' | '' = '') {
    if (order === this.sortDirection) order = '';
    this.sortDirection = order;

    if (order === '') {
      this.changeActive(this.activeFlux);
      return;
    }
    if (order === 'asc') {
      this.projectsTemplate.sort((projectA, projectB) => projectA.name.localeCompare(projectB.name));
      return;
    }
    if (order === 'desc') {
      this.projectsTemplate.sort((projectA, projectB) => projectB.name.localeCompare(projectA.name));
      return;
    }
  }
}

import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-task',
  templateUrl: './modal-task.component.html',
  styleUrls: ['./modal-task.component.scss']
})
export class ModalTaskComponent {
  activityForm: FormGroup;
  colors: string[] = ['#2685f0', '#8cce17', '#febd2b', '#ff8b2c', '#6343c9', '#db359c','#e4254b','#03c3a8'];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ModalTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // Recibe datos iniciales para editar
  ) {
    this.activityForm = this.fb.group({
      activity: [data?.activity || '', Validators.required],
      startDate: [data?.startDate || '', Validators.required],
      endDate: [data?.endDate || '', Validators.required],
      color: [data?.color || this.colors[0], Validators.required],
    }, { validators: this.dateRangeValidator() } );
  }
  ngOnInit() {
    this.startDate.valueChanges.subscribe(() => this.activityForm.updateValueAndValidity());
  this.endDate.valueChanges.subscribe(() => this.activityForm.updateValueAndValidity());
  }
  get startDate() {
    return this.activityForm.get('startDate');
  }

  get endDate() {
    return this.activityForm.get('endDate');
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.get('startDate')?.value;
      const endDate = control.get('endDate')?.value;
  
      if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
        return { invalidDateRange: true }; // Retorna un error si las fechas son inválidas
      }
  
      return null; // Sin errores si la validación es exitosa
    };
  }
  // Filtro para deshabilitar las fechas posteriores en el campo de fecha de inicio
  disableEndDates = (date: Date | null): boolean => {
    const endDate = this.endDate?.value;
    return !endDate || !date || date <= endDate; // Permite solo las fechas menores o iguales a la fecha de fin
  };

  // Filtro para deshabilitar las fechas anteriores en el campo de fecha de fin
  disableStartDates = (date: Date | null): boolean => {
    const startDate = this.startDate?.value;
    return !startDate || !date || date >= startDate; // Permite solo las fechas mayores o iguales a la fecha de inicio
  };
  onAdd(): void {
    if (this.activityForm.valid) {
      this.dialogRef.close(this.activityForm.value); // Retorna los valores al componente padre
    }
  }
  

}

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert2';

import { Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { log } from 'console';
import { AccessService } from 'src/app/services/access/access.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalOportunityComponent } from '../comercial/modal-oportunity/modal-oportunity.component';
import { UsersService } from 'src/app/services/users/users.service';
import { TimeService } from 'src/app/services/time/time.service';
import { TeamService } from 'src/app/services/team/team.service';
import { EmployeesService } from 'src/app/services';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { LogService } from 'src/app/services/log/log.service';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { Router } from '@angular/router';
import { AlertComponent, AlertDialogData } from '@components/alert/alert.component';
import { Swal2Loader } from '@consts/swal2-loader';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
declare var bootstrap: any;
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-oportunity',
  templateUrl: './oportunity.component.html',
  styleUrls: ['./oportunity.component.scss']
})


export class OportunityComponent {
  allOportunities: [any];
  public readonly breadcrumb: Breadcrumb[] = [ CRUMBS.oportunityManage ];
  @ViewChild('modalChangeStatus') modalChangeStatus!: ElementRef;
  @ViewChild('staffSelect') staffSelect: MatSelect;
  shouldLabelFloat:any;
  isNew = true;
  maskAlphaNum = (rawValue: string) => {
    return Array(rawValue.length).fill(/^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]*$/);
  };
  maskCode = (rawValue: string) => {
    return Array(rawValue.length).fill(/[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ]/);
  };
  maskAlpha = (rawValue: string) => {
    return Array(rawValue.length).fill(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/);
  };
  locales = listLocales();
  selectedCityCodes: string[];
  existDetail = false;
  modalFlux = '';
//
optionsTest: string[] = ['One', 'Two', 'Three'];
  originalCategory: any;
  originalStatus:any;
  usersByCompany: any;
  comercialForm!: FormGroup;
  toppings = new FormControl('');
  controlsPayment = ['dateApprove', 'dateOrderBuy', 'currency', 'amount', 'price', 'agreedPayments', 'dateProcessPayment', 'typePayment', 'paymentDocument', 'costCenter', 'billingFrequency', 'dateInitBilling', 'totalHoursQuoted'];
  controlsAsignacion = ['dateInitAsign', 'dateEndAsign', 'technicalLead', 'realHour'];
  controlsProyecto = ['dateInitProyect', 'dateEndProyect', 'typeProyect', 'metodology', 'technology', 'timeGaranty', 'proyectLead'];
  teamsList: any;
  catActivities: any;
  options = [];
  auxOptions = [];
  optionsStaff = [];
  auxStaff = [];
  filteredOptions: Observable<any>;
  filteredOptionsProyLead: Observable<string[]>;
  selectedOption: any = null;
  selectedOptionProyLead: any = null;
  auxteamsComplete = [];
  activeOportunityEditing: any;
  modelSelected: any = null;


  //controls a cambiar estatus de acuerdo a radio facturable
  constructor(private localeService: BsLocaleService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private accessService: AccessService,
    private router: Router,

    private userService: UsersService,
    private timeService: TimeService,
    private teamService: TeamService,
    private oportunityService: OportunityService,
    private cdr: ChangeDetectorRef,
    private employeeService: EmployeesService
  ) {
    swal.fire(Swal2Loader);
    this.teamService.getTeams().subscribe((data) => {
      this.teamsList = data;
    })
    this.timeService.getTimes().subscribe(data => {
      const filteredItems = data.filter(item => !item?.name?.toLowerCase().includes("incidencia"));

      this.catActivities = filteredItems;
    })
    
    this.oportunityService.getOportunities()
    .pipe(
      tap((data) => {
        // Procesa los datos de la primera llamada aquí
        this.allOportunities = data;
        const idOp = this.accessService.opportunityActiveIndex.value;
        if (idOp !== null && data.length !== 0) {
          this.activeOportunityEditing = this.allOportunities.find(oportunity => oportunity._id === idOp);
          this.comercialForm.patchValue(this.activeOportunityEditing);
          this.originalCategory = this.comercialForm.get('category')?.value;
          this.originalStatus = this.comercialForm.get('statusOportunity')?.value;
          this.isNew = false;
          this.breadcrumb.push({
            title: this.activeOportunityEditing.name,
            route: '/new-oportunity'
          })
        }
        else {
          this.breadcrumb.push({
            title: 'Crear propuesta comercial',
            route: '/new-oportunity'
          })
        }
      }),
      switchMap(() => this.employeeService.getAllCompanyEmployees(sessionStorage.getItem('idCompanySelected'))) // Encadena la segunda llamada
    )
    .subscribe((data: any) => {
      this.usersByCompany = data;
  
      if (!this.isNew) {
        const activeUserSelected = this.usersByCompany?.find(usuario => usuario._id === this.comercialForm.get('responsable2bcore')?.value);
        setTimeout(() => {
          this.onOptionSelected({
            name: `${activeUserSelected?.names} ${activeUserSelected?.surnames} ${activeUserSelected?.secondSurname}`,
            image: activeUserSelected?.image,
            _id: activeUserSelected?._id
          });
        }, 100);

        const activeProLead = this.usersByCompany?.find(usuario => usuario._id === this.comercialForm.get('proyectLead')?.value);
        setTimeout(() => {
         if(activeProLead) {
          this.onOptionSelectedProyLead({
            name: `${activeProLead?.names} ${activeProLead?.surnames} ${activeProLead?.secondSurname}`,
            image: activeProLead?.image,
            _id: activeProLead?._id
          });
         }
        }, 100);

      }
  
      // Procesa los datos obtenidos de la segunda llamada
      this.usersByCompany.forEach((user: any) => {
        const addUser = {
          name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
          image: user.image,
          _id: user._id
        };
        this.options.push(addUser);
        this.optionsStaff.push(addUser);
        this.auxStaff.push(addUser);
        this.auxOptions.push(addUser);
      });
  
      this.filteredOptionsProyLead = this.comercialForm.get('proyectLead').valueChanges.pipe(
        startWith(''), // Comienza con un valor vacío para activar el Autocomplete inmediatamente
        tap(value => {
          if (typeof value === 'string' && value.trim() === '') {
            // Si el input está vacío, limpiar selectedOption
            this.selectedOptionProyLead = null;
          }
        }),
        map(value => this._filter(value || ''))
      );
      this.filteredOptions = this.comercialForm.get('responsable2bcore').valueChanges.pipe(
        startWith(''), // Comienza con un valor vacío para activar el Autocomplete inmediatamente
        tap(value => {
          if (typeof value === 'string' && value.trim() === '') {
            // Si el input está vacío, limpiar selectedOption
            this.selectedOption = null;
          }
        }),
        map(value => this._filter(value || ''))
      );
  
      setTimeout(() => {
        const IdsStaff = this.comercialForm.get('authorizedStaff')?.value;
        const idsSet = new Set(IdsStaff);
        const staffData = this.usersByCompany
          .filter((user: any) => idsSet.has(user._id))
          .map((user: any) => ({
            name: `${user.names} ${user?.surnames} ${user?.secondSurname}`,
            image: user.image,
            _id: user._id
          }));
        this.comercialForm.get('authorizedStaff').setValue(staffData);
        this.comercialForm.get('authorizedStaff').updateValueAndValidity();
        let statusOp = this.comercialForm.get('statusOportunity').value;
         let categoryActive = this.comercialForm.get('category')?.value;
        if (statusOp === 'Aceptada') {
          this.controlsPayment.forEach((control: any) => {
            this.addValidatorControl(control);
          })
          if (categoryActive === 'Asignación') {
            this.controlsAsignacion.forEach((control: any) => {
              this.addValidatorControl(control);
            })
          } else if (categoryActive === 'Proyecto') {
            this.controlsProyecto.forEach((control: any) => {
              this.addValidatorControl(control);
            })
          }
        }
        if (statusOp === 'Standby') {
          this.addValidatorControl('reactivationDay');
        }
        if (statusOp === 'Cerrada') {
          this.addValidatorControl('commentDecline');
        }
   
        this.shouldLabelFloat = this.comercialForm.get('authorizedStaff').value && this.comercialForm.get('authorizedStaff').value.length > 0;
        this.onSelectionChangeMember({value: this.comercialForm.get('accessTeam').value })// Actualizamos el valor del FormControl

        setTimeout(() => {
          this.staffSelect._onChange(this.comercialForm.get('authorizedStaff').value);
        }, 100);
        this.cdr.detectChanges();
        this.comercialForm.updateValueAndValidity();
        swal.close();
      });
    });
  /////
    this.localeService.use('es');
    this.comercialForm = this.fb.group({
      category: ['Propuesta comercial', Validators.required],
      name: ['', Validators.required],
      code: ['', Validators.required],
      client: ['', Validators.required],
      responsableClient: ['', Validators.required],
      time: ['', Validators.required],
      viabilityEnd: ['', Validators.required],
      lastContact: ['', Validators.required],
      statusOportunity: ['', Validators.required],
      responsable2bcore: ['', Validators.required],
      accessTeam: ['', Validators.required],
      authorizedStaff: ['', Validators.required],
      //datos para oportunidad aceptada
      dateApprove: [''],
      dateOrderBuy: [''],
      currency: [''],
      amount: [''],
      price: [''],
      agreedPayments: [''],
      dateProcessPayment: [''],
      typePayment: [''],
      paymentDocument: [''],
      costCenter: [''],
      billingFrequency: [''],
      dateInitBilling: [''],
      totalHoursQuoted: [''],
      // datos para rechazo
      commentDecline: [''],
      // datos para reactivacion en standby
      reactivationDay: [''],
      // datos para cuando es asignacion
      dateInitAsign: [''],
      dateEndAsign: [''],
      technicalLead: [''],
      realHour: [''],
      //datos para cuando es proyecto
      dateInitProyect: [''],
      dateEndProyect: [''],
      typeProyect: [],
      metodology: [''],
      technology: [''],
      timeGaranty: [''],
      proyectLead: []

    });
  }
  // Alterna la selección de un chip
  toggleSelection(option: any): void {
    option.selected = !option.selected;
  }
  ngOnInit() {
  }

  convertToUpper(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.toUpperCase();
    this.comercialForm.get('code').setValue(inputElement.value);
  }

  // Función para mostrar el nombre en el input después de seleccionar la opción
  displayFn(option: any): string {
    return option ? option.name : '';
  }
  public registryHasError(controlName: string) {
    return this.comercialForm.controls[controlName]?.invalid && (this.comercialForm.controls[controlName]?.dirty || this.comercialForm.controls[controlName]?.touched);
  }

  // Método para manejar la opción seleccionada
  onOptionSelected(option: any) {
    this.selectedOption = option;
    this.comercialForm.get('responsable2bcore').setValue(option.name);
    this.cdr.detectChanges();
    this.comercialForm.updateValueAndValidity();
    
  }
  onOptionSelectedProyLead(option: any) {
    this.selectedOptionProyLead = option;
    this.comercialForm.get('proyectLead').setValue(option.name);
    this.cdr.detectChanges();
    this.comercialForm.updateValueAndValidity();
  }

  private _filter(name: any): any[] {
    let filterValue: string;
    if (typeof name === 'string') {
      filterValue = name.toLowerCase();
    } else if (typeof name === 'object' && name !== null && 'name' in name) {
      filterValue = name.name.toLowerCase();
    } else {
      return [];
    }
      return this.auxOptions.filter(option => option.name.toLowerCase().includes(filterValue));

  }
  remove(option: any): void {
    const currentValues = this.comercialForm.get('accessTeam').value as string[];
    const index = currentValues.indexOf(option);
    if (index >= 0) {
      currentValues.splice(index, 1);
      const matchingItems = this.teamsList.filter(team => 
        currentValues.some(name => name.toLowerCase() === team.name.toLowerCase())
      );
     if (matchingItems.length > 0) {
      const collaboratorIds = Array.from(
        new Set(matchingItems.flatMap(item => item.collaborators.map(collaborator => collaborator.id)))
      );
      const matchUser = this.auxStaff.filter(team => 
        collaboratorIds.some(name => name === team._id)
      );
      this.optionsStaff = matchUser;
     } else {
      this.optionsStaff = this.auxStaff;
     }
      this.comercialForm.get('accessTeam').setValue(currentValues); 
    }
  }
  removeUserChip(option: any): void {
    const currentValues = this.comercialForm.get('authorizedStaff').value as any[];
    const index = currentValues.indexOf(option);
 
    
    if (index >= 0) {
      currentValues.splice(index, 1); 
           
      this.comercialForm.get('authorizedStaff').setValue(currentValues); // Actualizamos el valor del FormControl
      this.onSelectionChange();
    }
  }
  onSelectionChangeMember(event: any) {
    const matchingItems = this.teamsList?.filter(team => 
      event?.value?.some(name => name?.toLowerCase() === team?.name.toLowerCase())
    );
   if (matchingItems.length > 0) {
    const collaboratorIds = Array.from(
      new Set(matchingItems.flatMap(item => item.collaborators.map(collaborator => collaborator.id)))
    );
    const matchUser = this.auxStaff.filter(team => 
      collaboratorIds.some(name => name === team._id)
    );
    this.optionsStaff = matchUser;
   } else {
    this.optionsStaff = this.auxStaff;
   }
  }
  onSubmit() {
    if (this.isNew) {
      this.existDetail = true;
      const formData = this.comercialForm.value;
      const staff: any = [];
      formData.authorizedStaff.forEach((data: any) => {
        staff.push(data._id)
      });
      formData.authorizedStaff = staff;

      const objectToSave: any = {
        accessTeam: formData.accessTeam,
        authorizedStaff: formData.authorizedStaff,
        category: formData.category,
        client: formData.client,
        code: formData.code,
        lastContact: formData.lastContact,
        name: formData.name,
        responsable2bcore: this.selectedOption?._id,
        responsableClient: formData.responsableClient,
        statusOportunity: formData.statusOportunity,
        time: formData.time,
        viabilityEnd: formData.viabilityEnd
      }
      this.oportunityService.createOportunity(objectToSave).subscribe((data) => {
        this.showSuccesDialog(false);
      });
    } else {
      //this.localOportunities.splice(this.accessService.opportunityActiveIndex.value,1);
      const formData = this.comercialForm.value;
      const staff: any = [];
      formData.authorizedStaff.forEach((data: any) => {
        staff.push(data._id)
      });
      formData.authorizedStaff = staff;
      formData.responsable2bcore = this.selectedOption._id;
      if (formData.category === 'Proyecto') {
        formData.proyectLead = this.selectedOptionProyLead._id
      }
      Object.keys(formData).forEach((key) => {
        if (formData[key] === null || formData[key] === undefined || formData[key] === "") {
          delete formData[key];
        }
      });
      let objectToSave: any = {
        id: this.activeOportunityEditing._id,
        body: formData
      }
      this.oportunityService.updateOportunity(objectToSave).subscribe((data) => {
        this.showSuccesDialog(true);
      });

      //localStorage.setItem('comercialForm', JSON.stringify(this.localOportunities));
    }


  }
  clearValidatorControl(control) {
    const controler = this.comercialForm.get(control);
        if (controler) {
          controler.clearValidators();
          this.comercialForm.get(control).setValue('');
          controler.updateValueAndValidity({ emitEvent: true });
        }
  }
  addValidatorControl(control) {
    const controler = this.comercialForm.get(control);
    if (controler) {
      controler.setValidators(Validators.required);
      controler.updateValueAndValidity({ emitEvent: true });
      controler?.markAsTouched();
    }
  }
  validateStatus(event: any) {
    const newValue = event.value; 
    const selectedValue = event.value;
    if (selectedValue === 'Aceptada') {
      this.controlsPayment.forEach((control: any) => {
        this.addValidatorControl(control);
      })
      this.clearValidatorControl('reactivationDay');
      this.clearValidatorControl('commentDecline');
    } else if (selectedValue === 'Cerrada') {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '464px',
        data: {
          icon: 'status_question',
          warn: false,
          title: '¿Estás seguro?',
          description: `Estás por cambiar el estatus de la propuesta comercial ${this.comercialForm.get('name').value} de ${this.comercialForm.get('client').value} a ‘Cerrada’.<br>¿Deseas continuar?`,
          close: 'Salir',
          confirm: 'Cambiar estatus'
        } as ConfirmDialogData,
      });

      dialogRef.afterClosed().subscribe(result => {        
        if (result === true) {
          this.controlsPayment.forEach((control: any) => {
            this.clearValidatorControl(control);
          })
          this.clearValidatorControl('reactivationDay');
          this.addValidatorControl('commentDecline');
          this.comercialForm.get('statusOportunity').setValue(newValue);
          this.comercialForm.get('statusOportunity').updateValueAndValidity();
          this.cdr.detectChanges();
          this.dialog.open(AlertComponent, {
            width: '464px',
            data: {
              icon: 'status_approved',
              title: 'Estatus actualizado',
              description: `Lamentamos que la propuesta ${this.comercialForm.get('name').value} no haya sido aceptada. Por favor, indícanos el motivo.`,
              close: 'Agregar descripción',
            }
          });
        } else {       
          this.clearValidatorControl('commentDecline');   
          this.comercialForm.get('statusOportunity').setValue(this.originalStatus);
          this.cdr.detectChanges();
          this.comercialForm.get('statusOportunity').updateValueAndValidity();
        }
      });
    }
    else if (selectedValue === 'Standby') {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '464px',
        data: {
          icon: 'status_question',
          warn: false,
          title: '¿Estás seguro?',
          description: `Cambiar el estatus a ‘Standby’ impedirá modificarlo nuevamente hasta su reactivación.<br>¿Deseas continuar?`,
          close: 'Salir',
          confirm: 'Cambiar estatus'
        } as ConfirmDialogData,
      });
      dialogRef.afterClosed().subscribe(result => {        
        if (result === true) {
          this.controlsPayment.forEach((control: any) => {
            this.clearValidatorControl(control);
            this.clearValidatorControl('commentDecline');
          })
         
          this.addValidatorControl('reactivationDay')
          this.comercialForm.get('statusOportunity').setValue(newValue);
          this.comercialForm.get('statusOportunity').updateValueAndValidity();
          this.cdr.detectChanges();
        } else {
          this.clearValidatorControl('reactivationDay');
          this.comercialForm.get('statusOportunity').setValue(this.originalStatus);
          this.comercialForm.get('statusOportunity').updateValueAndValidity();
          this.cdr.detectChanges();
        }
      });
    } else {
      this.clearValidatorControl('reactivationDay');
      this.originalStatus =  event.value;
      this.controlsPayment.forEach((control: any) => {
        this.clearValidatorControl(control)
      })
      this.clearValidatorControl('commentDecline');
    }
  }
  openModal() {
    const modalElement = this.modalChangeStatus.nativeElement;
    const modalInstance = new bootstrap.Modal(modalElement); // Crea la instancia del modal
    modalInstance.show(); // Muestra el modal
  }

  closeModal() {
    const modalElement = this.modalChangeStatus.nativeElement;
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  }
  onCategoryChange(): void {
    const newCategory = this.comercialForm.get('category')?.value;

    if (newCategory !== this.originalCategory) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '464px',
        data: {
          icon: 'status_question',
          warn: false,
          title: '¿Estás seguro?',
          description: `Estás por crear un${newCategory !== 'Proyecto' ? 'a' : ''} ${newCategory.toLowerCase()} a partir de${this.originalCategory.toLowerCase() === 'proyecto' ? 'l' : ' la'} ${this.originalCategory.toLowerCase()} ${this.comercialForm.get('name').value} ¿Deseas continuar?`,
          close: 'Salir',
          confirm: 'Cambiar estatus'
        } as ConfirmDialogData,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.originalCategory = newCategory;
          this.dialog.open(AlertComponent, {
            width: '464px',
            data: {
              icon: 'status_approved',
              title: '¡Perfecto!',
              description: `${this.comercialForm.get('name').value} de ${this.comercialForm.get('client').value} ahora es un${newCategory.toLowerCase() === 'proyecto' ? '' : 'a'} ${newCategory.toLowerCase()}. Por favor, completa el registro para continuar.`,
              close: 'Completar registro',
            }
          });
          console.log(this.originalCategory);
          
          if (this.originalCategory !== 'Propuesta comercial') {
            if (this.originalCategory === 'Asignación') {
              this.controlsAsignacion?.forEach((control) => {
                this.addValidatorControl(control)
              })
            } else {
              this.controlsProyecto?.forEach((control) => {
                this.addValidatorControl(control)
              })
            }
          } else {
            this.controlsAsignacion?.forEach((control) => {
              this.clearValidatorControl(control)
            })
            this.controlsProyecto?.forEach((control) => {
              this.clearValidatorControl(control)
            })
          }
        } else {
          this.comercialForm.get('category')?.setValue(this.originalCategory);
        }
      });
    }
  }
  public cancelNewOportunity() {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_question',
        warn: false,
        title: '¿Estás seguro?',  
        description: `Al cancelar el registro de est${this.comercialForm.get('category').value.toLowerCase() === 'proyecto' ? 'e' : 'a'} ${this.comercialForm.get('category').value.toLowerCase()}, no podrás guardar los cambios.`,
        close: 'Salir sin guardar',
        confirm: 'Seguir editando'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) {
        this.router.navigateByUrl('/comercial');
      }
    });
  }
 
   showSuccesDialog(isUpdate: boolean) {
    const msgAction = isUpdate == true ? 'actualizada' : 'creada'
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '464px',
      data: {
        icon: 'status_approved',
        warn: false,
        title: '¡Perfecto!',
        description: `La nueva propuesta comercial ha sido ${msgAction} correctamente.`,
        close: 'Salir',
        confirm: 'Ver detalles'
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
       // TODO: route ver detalles : result === true 
      const route = result === true ? '/comercial' : '/comercial';
      this.router.navigateByUrl(route);
    });
  }

  private showErrorDialog() {
    this.dialog.open(AlertComponent, {
      width: '464px',
      data: {
        icon: 'status_rejected',
        title: '¡Error!',
        description: 'No ha sido posible crear la propuesta comercial. Inténtalo de nuevo.',
        close: 'Aceptar',
      }
    });
  }
  onFocus(): void {
    this.shouldLabelFloat = true;
  }
  
  onBlur(): void {
    const controlValue = this.comercialForm.get('authorizedStaff')?.value;
    this.shouldLabelFloat = controlValue && controlValue.length > 0;
  }
  
  onSelectionChange(): void {
    const controlValue = this.comercialForm.get('authorizedStaff')?.value;
    this.shouldLabelFloat = controlValue && controlValue.length > 0;
  }
}

<div *ngIf="workPlan && workPlan.length > 0; else noContent" class="calendar deep-icon-expand custom-scroll" id="workPlan">
    <div class="calendar__row calendar__row--header p-0" [style]="'--weeks: ' + totalWeeks.length + ''">

        <div class="calendar__months">
            <div class="calendar__month" *ngFor="let month of totalMonths"
                [style]="'--width:' + month.percentage + '%;'">
                {{ month.month | date:'MMMM' | titlecase }}
            </div>
        </div>

        <div class="calendar__week" *ngFor="let week of totalWeeks; let i = index">
            {{ week.start | date:'dd ' }} - {{ week.end | date:'dd ' }}
        </div>
    </div>
    <div 
    class="calendar__row align-items-center" 
    *ngFor="let plan of this.auxTasks; index as i" 
    [style]="'--weeks: ' + totalWeeks.length"
  >
    <div class="calendar__chip text-truncate position-relative" matTooltipClass="deep-icon-expand" [style]="plan.style" (mouseenter)="checkOverflow($event)" matTooltip="{{ showTooltip ? plan.activity : '' }}"
    [matTooltipDisabled]="!showTooltip">
      {{ plan.activity }}
      <span 
        *ngIf="edition" 
        class="time-icon-edit" 
        (click)="openModal(plan, i)"
      ></span>
    </div>
  </div>
   

    <button mat-icon-button (click)="toggleFullscreen()" aria-label="Open fullscreen" id="buttonFullscreen">
        <span class="time-icon-full_screen fs-24"></span>
    </button>
</div>

<ng-template #noContent>
    <div class="no-content">
        <p>Sin plan de trabajo</p>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class CanDeactiveFormGuard {

  canDeactivate(component: any): boolean | Promise<boolean> {
    return component.canDeactivate();
  }
  
}

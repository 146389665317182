import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { isSameMonth, isSameDay, isFuture } from 'date-fns';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { formatDate } from '@angular/common';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { Router } from '@angular/router';
import { LogService } from 'src/app/services/log/log.service';
import { INCIDENCES } from '../root/incidence/incidences.const';

enum STATUS {
  APPROVED,
  PENDING,
  REJECTED,
  EMPTY,
}

interface CustomCalendarEvent extends CalendarEvent {
  status?: STATUS
}

interface WeekDay {
  date: Date
  day: number
  isFuture: boolean
  isPast: boolean
  isToday: boolean
  isWeekend: boolean
}

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class EmployeeComponent {
  public view: CalendarView = CalendarView.Week;
  public readonly CalendarView = CalendarView;
  public viewDate: Date = new Date();

  public readonly excludeDays: number[] = [0, 6];
  public readonly STATUS = STATUS;
  public readonly locale = 'es-ES';
  public readonly breadcrumb: Breadcrumb[] = [
    CRUMBS.colaboratorRegisterActivities,
  ];

  public events: CustomCalendarEvent[] = [];

  constructor(
    private router: Router,
    private logService: LogService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const idUser = sessionStorage.getItem('idEmp');
    this.logService.getLogsByCollaborator(idUser).subscribe(activitiesLog=>{
      if(activitiesLog){
        const result = this.getUniqueElementsByDate(activitiesLog);
        this.events = result
          .filter(log => {
            // Verificamos si 'time' NO incluye las palabras a excluir
            const incidence = INCIDENCES.find((incidence) => incidence.value == log.time.toLowerCase());
            return !incidence;
          })
          .map((activity) => {
          return {
            allDay: true,
            start: new Date(activity.date),
            title: 'Empty Event',
            status: activity.status === 'approved' ? this.STATUS.APPROVED: activity.status === 'rejected' ? this.STATUS.REJECTED : this.STATUS.PENDING,
            id: activity._id
          };
        });
        this.changeDetectorRef.detectChanges();
      }
    })
  }
  
  private readonly priority = { "rejected": 1, "pending": 2, "approved": 3 };
  private getUniqueElementsByDate(data) {
    const groupedByDate = {};
    data.forEach(item => {
        const date = new Date(item.date).toISOString().split('T')[0]; // Obtener solo la fecha
        if (!groupedByDate[date]) {
            groupedByDate[date] = [];
        }
        groupedByDate[date].push(item);
    });

    const result = [];

    for (const date in groupedByDate) {
        const items = groupedByDate[date];
        // Ordenar los elementos por prioridad de estado
        items.sort((a, b) => this.priority[a.status] - this.priority[b.status]);
        // Tomar el primer elemento (el de mayor prioridad)
        result.push(items[0]);
    }

    return result;
  }
    
  // ! General
  setView(view: CalendarView) {
    this.view = view;
  }

  // ! Week
  public getWeek(days: WeekDay[], events: CustomCalendarEvent[]) {
    const week = days.map((day) => {
      const event = events.find((event => isSameDay(event.start, day.date))) ?? null;
      return { ...day, event }
    })
    return week;
  }

  public getStatusLabel(status: STATUS = STATUS.EMPTY) {
    switch (status) {
      case STATUS.APPROVED:
        return 'Aprobadas';
      case STATUS.PENDING:
        return 'Por aprobar';
      case STATUS.REJECTED:
        return 'Rechazadas';
      case STATUS.EMPTY:
      default:
        return 'Por registrar';
    }
  }

  public getButtonLabel(status: STATUS = STATUS.EMPTY): string {
    switch (status) {
      case STATUS.APPROVED:
      case STATUS.REJECTED:
      case STATUS.PENDING:
        return 'Ver registro';
      case STATUS.EMPTY:
      default:
        return 'Registrar';
    }
  }

  // ! Month
  dayClicked({ date, events }: { date: Date; events: CustomCalendarEvent[] }): void {
    if (isFuture(date)) return;

    this.router.navigate(['/register', {time: date.getTime()}]);

    /*
    if (events.length == 0) { // Nueva actividad
      this.router.navigate(['/register', {time: date.getTime()}]);
      return;
    } 

    const penddingEvents = events.find((event) => event.status !== STATUS.APPROVED);
    if (penddingEvents) { // Editar actividad
      this.router.navigate(['/update', penddingEvents.id]);
    } else { // Historial
      this.router.navigate(['/record']);
    }
    */
  }

  isSameMonth(date: Date): string {
    return isSameMonth(date, this.viewDate) ? '' : formatDate(date, 'MMM', this.locale);
  }
}

<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="rectangle-main">
                <div>
                    <span class="time-icon-edit align-middle mr-3"></span> <span>Vista edición</span>
                    <p class="mb-0">Editando reporte: {{realInfoPr?.name}}</p>
                </div>
                <div>
                    <div class="cancel-button cursor-pointer" (click)="cancelNewOportunity()">Cancelar edición</div>
                </div>
            </div>
            <form [formGroup]="EditProyectForm">
                <div class="sec-general-info col-12">
                    <div class="d-flex justify-content-between col-12">
                        <div>
                            <span class="text-proyect mr-3">Proyecto: <strong>{{realInfoPr?.name}}</strong></span>
                            <span class="chip-status" *ngIf="realInfoPr?.statusOportunity">{{realInfoPr?.statusOportunity}}</span>
                        </div>
                        <div class="d-flex" matTooltip="Sprint" matTooltipPosition="above">
                            <div class="fs-25 time-icon-sprint align-middle mr-3"></div>
                            <div class=" content-input form-group">
                                <mat-form-field class="sprint-input">
                                    <mat-label style="font-size: 13px;">Sprint*</mat-label>
                                    <input matInput formControlName="sprint">
                                </mat-form-field>
                            </div>
                        </div>
                        <button class="" type="button" mat-button matTooltip="Team" matTooltipPosition="above">
                            <span class="fs-25 time-icon-menu_team align-middle mr-1"></span>
                            <span class="overview__team-count align-middle">
                                {{ realInfoPr?.authorizedStaff?.length }}
                            </span>
                        </button>
                        <button class="" type="button" mat-button matTooltip="Created At" matTooltipPosition="above">
                            <span class="fs-25 time-icon-calendar_active align-middle mr-1"></span>
                            <span class="overview__date align-middle">
                                {{ proyectInfo.overview.updatedAt | date:'shortDate' }}
                            </span>
                        </button>
                    </div>
                    <div class="col-12 mt-3 d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                            <img [src]="responsable2bcoreData?.image ? (responsable2bcoreData?.image | imagePath: 'profilePicture') :'assets/img/person.svg'"
                                class="img-leader" alt="">
                            <div class="align-items-center d-flex flex-column">
                                <p class="name-leader">{{responsable2bcoreData?.name}}</p>
                                <p class="mb-0">Líder de proyecto</p>
                            </div>
                        </div>
                        <div>
                            <p class="fs-16 mb-0">Miembros del Staff</p>
                            <app-avatar-collection [allEmployees]="dataToAvatar"
                                [team]="dataToAvatar"></app-avatar-collection>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0 mt-5">
                    <mat-expansion-panel expanded #aboutTemp>
                        <mat-expansion-panel-header class="pl-0">
                            <mat-panel-title> Acerca del proyecto </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="subtitle mb-3 mt-5">
                            Descripción
                        </div>
                        <mat-form-field class="w-100 mb-4 text-area">
                            <textarea matInput class="w-100 mb-4" formControlName="proyectDescription"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
                        <div class="subtitle mb-3">
                            Objetivos
                        </div>
                        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)"
                            formArrayName="objectiveInputs">
                            <div class="d-flex align-items-center mb-4"
                                *ngFor="let input of objectiveInputs.controls; let i = index" [formGroupName]="i"
                                cdkDrag>
                                <span class="time-icon-drag_and_drop fs-25 mr-2"></span>
                                <mat-form-field class="w-100">
                                    <input matInput formControlName="objective">
                                </mat-form-field>
                                <span class="time-icon-option_delete fs-25 ml-3" (click)="removeInput(i)"></span>
                            </div>

                        </div>
                        <div class="d-flex align-items-center mb-4">
                            <mat-form-field class="w-100">
                                <input matInput formControlName="defaultObjective" placeholder="Objetivo">
                            </mat-form-field>
                        </div>
                        <div class="custom-button" [disabled]="!EditProyectForm.get('defaultObjective').value"
                            [ngClass]="EditProyectForm.get('defaultObjective').value ? '' :'disabled'"
                            (click)="addInput(EditProyectForm.get('defaultObjective').value)">
                            <span style="color: #FFFFFF;" class="time-icon-time_plus mr-2 fs-20"></span> Agregar
                            objetivo
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded #dependenciesTemp>
                        <mat-expansion-panel-header class="pl-0">
                            <mat-panel-title> Dependencias</mat-panel-title>
                        </mat-expansion-panel-header>
                        <table mat-table [dataSource]="dataSource" formArrayName="dependenciesInputs" matSort
                            class="deep-table" cdkDropList (cdkDropListDropped)="dropDependncies($event)">
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-0"
                                    sortActionDescription="Sort by description">
                                    Dependencia
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i"
                                    class="d-flex align-items-center pl-0">
                                    <span class="time-icon-drag_and_drop fs-25 mr-2"></span>
                                    <mat-form-field>
                                        <input matInput formControlName="dependency">
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="inCharge">
                                <th mat-header-cell *matHeaderCellDef>
                                    Responsable
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                                    <mat-form-field class="" appearance="none">
                                        <img [src]="dataUsersDependencies[i].image ? (dataUsersDependencies[i].image | imagePath: 'profilePicture') :'assets/img/person.svg'" class="custom-input-image" alt="Imagen seleccionada">
                                        <input type="text" matInput [matAutocomplete]="auto"
                                            formControlName="idResponsable"
                                            [ngStyle]="{'background-image': dataUsersDependencies[i] ? 'url(' + dataUsersDependencies[i].image + ')' : '', 'background-size': '30px 30px', 'background-position': '5px center', 'background-repeat': 'no-repeat', 'padding-left': dataUsersDependencies[i] ? '40px' : '10px'}"
                                            [displayWith]="displayFn">

                                        <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="onOptionSelectedArray($event.option.value,dataUsersDependencies[i],i)">
                                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'"
                                                width="30" height="30" style="margin-right: 10px;">
                                              
                                                {{ option.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                    Estatus
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                                    <div class="position-relative">
                                        <mat-form-field class="custom-label" appearance="fill" class="">
                                            <mat-label>Estatus</mat-label>
                                            <mat-select formControlName="status">
                                                <mat-option value="To Do">
                                                    To Do
                                                </mat-option>
                                                <mat-option value="WIP">
                                                    WIP
                                                </mat-option>
                                                <mat-option value="Done">
                                                    Done
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span class="time-icon-arrow_expand fs-16"></span>
                                    </div>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="commitmentAt">
                                <th mat-header-cell *matHeaderCellDef>
                                    Compromiso
                                </th>
                                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                                    <mat-form-field class="example-full-width" appearance="fill">
                                        <input matInput [matDatepicker]="picker" formControlName="deliveryDate">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                                            <mat-icon class="time-icon-calendar_active"
                                                matDatepickerToggleIcon></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="delete">
                                <th mat-header-cell *matHeaderCellDef>

                                </th>
                                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i"> <span
                                        class="time-icon-option_delete fs-25" (click)="removeDependency(i)"></span>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <div class="example-list">
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag
                                    cdkDragLockAxis="y"></tr>
                            </div>


                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                            </tr>
                            <!-- Fila Estática -->

                        </table>
                        <table mat-table [dataSource]="dataSourceInit" matSort class="deep-table custom-table">
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-0 d-none"
                                    sortActionDescription="Sort by description">
                                </th>
                                <td mat-cell *matCellDef="let element" class="d-flex align-items-center pl-0">
                                    <span class="time-icon-drag_and_drop fs-25 mr-2"></span>
                                    <mat-form-field class="custom-label">
                                        <mat-label>Dependencia</mat-label>
                                        <input matInput formControlName="defaultDependencie">
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="inCharge">
                                <th mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-form-field class="custom-label" appearance="none">
                                        <mat-label>Responsable</mat-label>
                                        <img  [hidden]="!selectedOption" [src]="selectedOption?.image ? (selectedOption?.image | imagePath: 'profilePicture') :'assets/img/person.svg'" class="custom-input-image" alt="Imagen seleccionada">
                                        <input type="text" matInput [matAutocomplete]="auto"
                                            formControlName="defaultResponsable"
                                            [ngStyle]="{ 'background-size': '30px 30px', 'background-position': '5px center', 'background-repeat': 'no-repeat', 'padding-left': selectedOption ? '40px' : '10px'}"
                                            [displayWith]="displayFn">

                                        <mat-autocomplete #auto="matAutocomplete"
                                            (optionSelected)="onOptionSelected($event.option.value)">
                                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                <img [src]="option.image ? (option.image | imagePath: 'profilePicture') :'assets/img/person.svg'"
                                                    width="30" height="30" style="margin-right: 10px;">
                                                {{ option.name }}
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="position-relative">
                                        <mat-form-field appearance="fill" class="custom-label w-100">
                                            <mat-label>Estatus</mat-label>
                                            <mat-select formControlName="defaultStatus">
                                                <mat-option value="To Do">
                                                    To Do
                                                </mat-option>
                                                <mat-option value="WIP">
                                                    WIP
                                                </mat-option>
                                                <mat-option value="Done">
                                                    Done
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span class="time-icon-arrow_expand fs-16"></span>
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="commitmentAt">
                                <th mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-form-field class="example-full-width custom-label" appearance="fill">
                                        <mat-label class="custom-label">Compromiso</mat-label>
                                        <input matInput [matDatepicker]="picker" formControlName="defaultDeliveryDate">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                                            <mat-icon class="time-icon-calendar_active"
                                                matDatepickerToggleIcon></mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="delete">
                                <th mat-header-cell *matHeaderCellDef>

                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class=" fs-25" style="width: 25px;"></div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <div class="example-list">
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </div>


                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                            </tr>
                            <!-- Fila Estática -->

                        </table>

                        <div class="custom-button mt-3" (click)="addDependency()"
                            [disabled]="(!EditProyectForm.get('defaultDependencie').value && !EditProyectForm.get('defaultResponsable').value || !EditProyectForm.get('defaultStatus').value || !EditProyectForm.get('defaultDeliveryDate').value )"
                            [ngClass]="(EditProyectForm.get('defaultDependencie').value && EditProyectForm.get('defaultResponsable').value && EditProyectForm.get('defaultStatus').value && EditProyectForm.get('defaultDeliveryDate').value ) ? '' :'disabled'">
                            <span style="color: #FFFFFF;" class="time-icon-time_plus mr-2 fs-20"></span> Agregar
                            dependencia
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded #workPlanTemp>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="justify-content-between">
                                Plan de trabajo
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-work-plan [workPlan]="realInfoPr?.workPlan" [edition]="true" (workPlanChange)="onWorkPlanUpdated($event)"></app-work-plan>
                        <div class="custom-button mt-3" (click)="openModal()"
                       >
                        <span style="color: #FFFFFF;" class="time-icon-time_plus mr-2 fs-20" ></span> Agregar
                        actividad
                    </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded #adicionalActivities>
                        <mat-expansion-panel-header class="pl-0">
                            <mat-panel-title> Actividades adicionales</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-3">
                            <div class="col-9 align-items-center title-label">
                                Actividad adicional
                            </div>
                            <div class="col-3 d-flex align-items-center title-label">
                                Estatus
                            </div>
                        </div>
                        <div cdkDropList class="example-list" (cdkDropListDropped)="dropAdicionalActivities($event)"
                            formArrayName="aditionalTasksInputs">
                            <div class="d-flex align-items-center mb-4 row"
                                *ngFor="let additionalTasks of aditionalTasksInputs.controls; let i = index"
                                [formGroupName]="i" cdkDrag>
                                <div class="col-9 align-items-center d-flex">
                                    <span class="time-icon-drag_and_drop fs-25 mr-2"></span>
                                    <mat-form-field class="w-100 mr-2">
                                        <input matInput formControlName="activity">
                                    </mat-form-field>
                                </div>
                                <div class="col-3 d-flex align-items-center">
                                    <div class="position-relative">
                                        <mat-form-field appearance="fill" class="custom-label">
                                            <mat-label>Estatus</mat-label>
                                            <mat-select formControlName="status">
                                                <mat-option value="To Do">
                                                    To Do
                                                </mat-option>
                                                <mat-option value="WIP">
                                                    WIP
                                                </mat-option>
                                                <mat-option value="Done">
                                                    Done
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span class="time-icon-arrow_expand fs-16"></span>
                                    </div>
                                    <span class="time-icon-option_delete fs-25 ml-3" (click)="removeTask(i)"></span>
                                </div>

                            </div>

                        </div>
                        <div class="row">
                            <div class="col-9 ">
                                <mat-form-field class="custom-label w-100">
                                    <mat-label>Tarea</mat-label>
                                    <input matInput formControlName="defAditionalActivity">
                                </mat-form-field>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                                <div class="position-relative">
                                    <mat-form-field appearance="fill" class="custom-label">
                                        <mat-label>Estatus</mat-label>
                                        <mat-select formControlName="defAditActStatus">
                                            <mat-option value="To Do">
                                                To Do
                                            </mat-option>
                                            <mat-option value="WIP">
                                                WIP
                                            </mat-option>
                                            <mat-option value="Done">
                                                Done
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="time-icon-arrow_expand fs-16"></span>
                                </div>
                            </div>
                        </div>

                        <div class="custom-button mt-3"
                            [disabled]="(!EditProyectForm.get('defAditionalActivity').value || !EditProyectForm.get('defAditActStatus').value)"
                            [ngClass]="EditProyectForm.get('defAditionalActivity').value && EditProyectForm.get('defAditActStatus').value ? '' :'disabled'"
                            (click)="addAditionalTask()">
                            <span style="color: #FFFFFF;" class="time-icon-time_plus mr-2 fs-20"></span> Agregar
                            actividad
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel expanded #risks>
                        <mat-expansion-panel-header class="pl-0">
                            <mat-panel-title>Riesgos</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row mt-3">
                            <div class="col-9 align-items-center title-label">
                                Riesgo
                            </div>
                            <div class="col-3 d-flex align-items-center title-label">
                                Estatus
                            </div>
                        </div>
                        <div cdkDropList class="example-list" (cdkDropListDropped)="dropRisk($event)" formArrayName="aditionalRiskInputs">
                            <div class="d-flex align-items-center mb-4 row"
                            *ngFor="let additionalRisks of aditionalRiskInputs.controls; let i = index"
                            [formGroupName]="i" cdkDrag>
                            <div class="col-9 align-items-center d-flex">
                                <span class="time-icon-drag_and_drop fs-25 mr-2"></span>
                                <mat-form-field class="w-100 mr-2">
                                    <input matInput formControlName="activity">
                                </mat-form-field>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                                <div class="position-relative">
                                    <mat-form-field appearance="fill" class="custom-label">
                                        <mat-label>Estatus</mat-label>
                                        <mat-select formControlName="status">
                                            <mat-option value="Por atender">
                                                Por atender
                                            </mat-option>
                                            <mat-option value="En atención">
                                                En atención
                                            </mat-option>
                                            <mat-option value="Atendido">
                                                Atendido
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="time-icon-arrow_expand fs-16"></span>
                                </div>
                                <span class="time-icon-option_delete fs-25 ml-3" (click)="removeRisk(i)"></span>
                            </div>

                        </div>

                        </div>
                        <div class="row">
                            <div class="col-9 ">
                                <mat-form-field class="custom-label w-100">
                                    <mat-label>Riesgo</mat-label>
                                    <input matInput formControlName="defRisk">
                                </mat-form-field>
                            </div>
                            <div class="col-3 d-flex align-items-center">
                                <div class="position-relative">
                                    <mat-form-field appearance="fill" class="custom-label">
                                        <mat-label>Estatus</mat-label>
                                        <mat-select formControlName="defRiskStatus">
                                            <mat-option value="Por atender">
                                                Por atender
                                            </mat-option>
                                            <mat-option value="En atención">
                                                En atención
                                            </mat-option>
                                            <mat-option value="Atendido">
                                                Atendido
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span class="time-icon-arrow_expand fs-16"></span>
                                </div>
                            </div>
                        </div>
                        <div class="custom-button mt-3"
                            [disabled]="(!EditProyectForm.get('defRisk').value || !EditProyectForm.get('defRiskStatus').value)"
                            [ngClass]="EditProyectForm.get('defRisk').value && EditProyectForm.get('defRiskStatus').value ? '' :'disabled'"
                            (click)="addAditionalRisk()">
                            <span style="color: #FFFFFF;" class="time-icon-time_plus mr-2 fs-20"></span> Agregar
                            riesgo
                        </div>
                    </mat-expansion-panel>
                    <div class="col-12 d-flex justify-content-end mb-5 sep-top">
                        <button class="button-custom-cancel mr-2"
                            (click)="cancelNewOportunity()">Cancelar</button>

                        <button class="button-accept" (click)="submitForm()" [disabled]="EditProyectForm.invalid"
                            [ngClass]="!EditProyectForm.valid ? 'disabled' : ''"
                            >Guardar cambios</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './modules/landing/landing.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { GuideOneComponent } from './modules/questionary/guide-one/guide-one.component';
import { GuideTwoComponent } from './modules/questionary/guide-two/guide-two.component';
import { GuideThreeComponent } from './modules/questionary/guide-three/guide-three.component';
import { GuidesSummaryComponent } from './modules/questionary/guides-summary/guides-summary.component';
import { PrivateRouteGuard, SimplePrivateRouteGuard} from './guards';
import { CompanyManagementComponent } from './modules/admin/company-management/company-management.component';
import { GuestReplyComponent } from './modules/invite/guest-reply/guest-reply.component';
import { RegistryComponent } from './modules/complaint-box/registry/registry.component';
import { QuizComponent } from './modules/working/quiz/quiz.component';
import { SingleSigninComponent } from './modules/login/single-signin/single-signin.component';
import { TokenResolverService } from './resolver/token-resolver.service';
import { NoUserDataComponent } from './modules/login/no-user-data/no-user-data.component';
import { EmployeeComponent } from './modules/time-management/employee/employee.component';
import { RootComponent } from './modules/time-management/root/root.component';
import { RegisterHoursComponent } from './modules/time-management/register-hours/register-hours.component';
import { RecordComponent } from './modules/time-management/record/record.component';
import { ComercialComponent } from './modules/time-management/comercial/comercial.component';
import { OportunityComponent } from './modules/time-management/oportunity/oportunity.component';
import { ReportComponent } from './modules/time-management/root/report/report.component';
import { BackupComponent } from './modules/time-management/root/backup/backup.component';
import { DetailsComponent } from './modules/time-management/root/details/details.component';
import { EditProyectsComponent } from '@pmo/edit-proyects/edit-proyects.component';
import { TimeManagementGuard } from './guards/time-management/time-management.guard';
import { CanDeactiveFormGuard } from './modules/time-management/can-deactive-form.guard';

const routes: Routes = [
  // { path: 'home', loadChildren: () => import('./modules/home/home.module').then(p => p.HomeModule), canActivate: [PrivateRouteGuard] },
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(p => p.HomeModule), resolve: { access: TokenResolverService } },
  { path: 'NOM-projects', loadChildren: () => import('./modules/questionary/questionary.module').then(p => p.QuestionaryModule), canActivate: [PrivateRouteGuard] },
  { path: 'employees', loadChildren: () => import('./modules/employees/employees.module').then(p => p.EmployeesModule), canActivate: [PrivateRouteGuard] },
  // { path: 'login', loadChildren: () => import('./modules/login/login.module').then(p => p.LoginModule) },
  {path: 'nodata', component: NoUserDataComponent},
  { path: 'signin', component: SingleSigninComponent },
  { path: 'administration-companies', component: CompanyManagementComponent, canActivate: [SimplePrivateRouteGuard] },
  { path: 'guide-one', component: GuideOneComponent },
  { path: 'guide-two', component: GuideTwoComponent },
  { path: 'guide-three', component: GuideThreeComponent },
  { path: 'guides-summary', component: GuidesSummaryComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'box', loadChildren: () => import('./modules/complaint-box/complaint-box.module').then(m => m.ComplaintBoxModule), canActivate: [PrivateRouteGuard] },
  { path: 'invite', loadChildren: () => import('./modules/invite/invite.module').then(m => m.InviteModule), canActivate: [PrivateRouteGuard] },
  { path: 'invitation-reply', component: GuestReplyComponent },
  { path: 'comment', component: RegistryComponent , canActivate: [PrivateRouteGuard]},
  { path: 'posts', loadChildren: () => import('./modules/communication/communication.module').then(m => m.CommunicationModule), canActivate: [PrivateRouteGuard]},
  { path: 'work', loadChildren: () => import('./modules/working/working.module').then(m => m.WorkingModule), canActivate: [PrivateRouteGuard]},
  { path: 'quiz', component: QuizComponent},
  { path: 'hours-employee', component: EmployeeComponent},
  { path: 'hours-teams', loadChildren: () => import('./modules/time-management/root/root.module').then(m => m.RootModule)},
  { path: 'register', component: RegisterHoursComponent, canDeactivate:[CanDeactiveFormGuard]},
  { path: 'update/:id', component: RegisterHoursComponent },
  { path: 'record', component: RecordComponent},
  { path: 'comercial', component: ComercialComponent,canActivate: [PrivateRouteGuard,TimeManagementGuard]},
  {
    path: 'edit-proyect',
    component: EditProyectsComponent
  },
  { path: 'new-oportunity', component: OportunityComponent},
  { path: 'collaborator', loadChildren: () => import('@collaborator/collaborator.module').then(m => m.CollaboratorModule) },
  { path: 'teams', loadChildren: () => import('@pmo/teams/teams.module').then(m => m.TeamsModule) },
  { path: 'projects', loadChildren: () => import('@pmo/projects/projects.module').then(m => m.ProjectsModule) },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

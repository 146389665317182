import { catalogActivities } from "../register-hours/activities.const"
import { proposals } from "@pmo/projects/projects.const"

export interface Activity {
    id: string
    date: Date
    activity: string
    type: string
    duration: {
        hours: number
        minutes: number
    }
    description: string
    status: string
}

export const defaultNoAct = [
    {
        code: 'NA',
        name: 'No aplica',
        time:''
    },
    {
        code: 'NO_SE',
        name: 'No lo sé. Requiero apoyo de mi líder.',
        time:''
    },
    
]

export const defaultAlianzas = [
    {
        code: 'A_AWS',
        name: 'Alianza con AWS',
        time:''
    },
    {
        code: 'A_AXWAY',
        name: 'Alianza con Axway',
        time:''
    },
    {
        code: 'A_AZURE',
        name: 'Alianza con Azure',
        time:''
    },
    {
        code: 'A_DELL',
        name: 'Alianza con Dell',
        time:''
    },
    {
        code: 'A_GCP',
        name: 'Alianza con GCP',
        time:''
    },
    {
        code: 'A_IBM_STERLING',
        name: 'Alianza con IBM Sterling',
        time:''
    },    {
        code: 'A_INGRAM',
        name: 'Alianza con Ingram',
        time:''
    },    {
        code: 'A_LENOVO',
        name: 'Alianza con Lenovo',
        time:''
    },    {
        code: 'A_REDHAT',
        name: 'Alianza con RedHat',
        time:''
    },    {
        code: 'A_REDIS',
        name: 'Alianza con Redis',
        time:''
    },
    {
        code: 'A_SALESFORCE',
        name: 'Alianza con Salesforce',
        time:''
    },
    {
        code: 'A_sAP',
        name: 'Alianza con SAP',
        time:''
    },
    {
        code: 'A_SPICE',
        name: 'Alianza con Spice',
        time:''
    },
    {
        code: 'NO_SE',
        name: 'No lo sé. Requiero apoyo de mi líder.',
        time:''
    },
    
]

export const defaultManageTeams = [
    {
        code: 'COORD_ACT',
        name: 'Coordinación de actividades',
        time:'ORDINARIO: GESTIÓN DE EQUIPOS(LÍDERES)'
    },
    {
        code: 'SUP',
        name: 'Supervisión',
        time:'ORDINARIO: GESTIÓN DE EQUIPOS(LÍDERES)'
    },
    {
        code: 'RETRO',
        name: 'Retroalimentación',
        time:'ORDINARIO: GESTIÓN DE EQUIPOS(LÍDERES)'
    },
    
]
export const defaultActArea = [
    {
        code: 'APROV_INSUMOS',
        name: 'Aprovisionamiento de insumos',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    },
    {
        code: 'MAN_REDES_SOC',
        name: 'Manejo de redes sociales',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    },
    {
        code: 'MANT_SIST',
        name: 'Mantenimiento de sistemas',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    },
    {
        code: 'PROC_ADMIN',
        name: 'Procesos administrativos',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    },
    {
        code: 'SESION_AREA',
        name: 'Sesiones del área',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    },
    {
        code: 'SOP_USUARIOS',
        name: 'Soporte a usuarios',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    },
    {
        code: 'TRASL_EQ',
        name: 'Traslado de equipos',
        time:'ORDINARIO: OPERACIÓN DE AREA'
    }
    
]
export const activities: Activity[] = [
    {
        id: '1',
        date: new Date('07/24/24'),
        activity: catalogActivities[0].value,
        type: proposals[1].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo MVP para presentación con cliente.',
        status: 'approved',
    },
    {
        id: '2',
        date: new Date('07/24/24'),
        activity: catalogActivities[0].value,
        type: proposals[2].code,
        duration: {
            hours: 2,
            minutes: 0,
        },
        description: 'Definición de sistema de diseño y elementos para el prototipo.',
        status: 'pending',
    },
    {
        id: '3',
        date: new Date('07/24/24'),
        activity: catalogActivities[0].value,
        type: proposals[3].code,
        duration: {
            hours: 0,
            minutes: 30,
        },
        description: 'Daily con equipo',
        status: 'approved',
    },
    /*
    {
        id: '4',
        date: new Date('07/20/24'),
        activity: catalogActivities[0].value,
        type: proposals[4].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'pending',
    },
    */
    {
        id: '5',
        date: new Date('07/19/24'),
        activity: catalogActivities[0].value,
        type: proposals[5].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'rejected',
    },
    /*
    {
        id: '6',
        date: new Date('07/18/24'),
        activity: catalogActivities[0].value,
        type: proposals[6].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'approved',
    },
    {
        id: '7',
        date: new Date('07/17/24'),
        activity: catalogActivities[0].value,
        type: proposals[7].code,
        duration: {
            hours: 7,
            minutes: 30,
        },
        description: 'Diseño de prototipo R1',
        status: 'rejected',
    },
    */
]